import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SharedServicesModule } from '../shared-services.module';
import { UnsavedChangesDialogComponent } from './unsaved-changes-dialog.component';

@Injectable({
  providedIn: SharedServicesModule,
})
export class UnsavedChangesDialogService {
  constructor(private readonly dialog: MatDialog) {}

  public showDialog({
    onSave = () => {},
    onSkip = () => {},
    onCancel = () => {},
  }: {
    onSave?: () => void;
    onSkip?: () => void;
    onCancel?: () => void;
  }): boolean | Observable<boolean> {
    return this.dialog
      .open<UnsavedChangesDialogComponent, void, boolean>(
        UnsavedChangesDialogComponent
      )
      .afterClosed()
      .pipe(
        map((save) => {
          if (save === false) {
            // specifically clicked no to save changes
            onSkip();

            return true;
          } else if (save !== true) {
            // the dialog was closed, so we don't want to do anything
            onCancel();

            return false;
          }

          // specifically clicked yes, so we want to save
          onSave();

          return true;
        })
      );
  }
}
