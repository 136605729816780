import { DomainModelHelper } from '../utils/DomainModelHelper';
import { Address } from './address';
import { PersistableBase } from './persistable-base';

export class AddressModel extends PersistableBase implements Address {
  readonly id?: string;
  readonly addressHash?: string;
  readonly addressLine1: string;
  readonly addressLine2?: string;
  readonly addressLine3?: string;
  readonly city?: string;
  readonly countryCode?: string;
  readonly country?: string;
  readonly county?: string;
  readonly display?: string;
  readonly isCloseMatch?: string;
  readonly latitude?: string;
  readonly locationCode?: string;
  readonly locationConfidenceScore?: string;
  readonly locationType?: string;
  readonly longitude?: string;
  readonly matchConfidenceScore?: string;
  readonly matchType?: string;
  readonly postalCode: string;
  readonly postalCodeSuffix?: string;
  readonly stateCode?: string;
  readonly timeZoneCode?: string;
  readonly timeZoneName?: string;

  static readonly DEFAULT_CONFIG: Address = {
    addressLine1: '',
    postalCode: '',
    createdBy: '',
    lastModifiedBy: '',
  };

  constructor(config: Address) {
    super(config);
    this.id = config ? DomainModelHelper.ensureDataIsString(config.id) : null;
    this.addressHash = config
      ? DomainModelHelper.ensureDataIsString(config.addressHash)
      : null;
    this.addressLine1 = config
      ? DomainModelHelper.ensureDataIsString(config.addressLine1)
      : null;
    this.addressLine2 = config
      ? DomainModelHelper.ensureDataIsString(config.addressLine2)
      : null;
    this.addressLine3 = config
      ? DomainModelHelper.ensureDataIsString(config.addressLine3)
      : null;
    this.city = config
      ? DomainModelHelper.ensureDataIsString(config.city)
      : null;
    this.countryCode = config
      ? DomainModelHelper.ensureDataIsString(config.countryCode)
      : null;
    this.country = config
      ? DomainModelHelper.ensureDataIsString(config.country)
      : null;
    this.county = config
      ? DomainModelHelper.ensureDataIsString(config.county)
      : null;
    this.display = config
      ? DomainModelHelper.ensureDataIsString(config.display)
      : null;
    this.isCloseMatch = config
      ? DomainModelHelper.ensureDataIsString(config.isCloseMatch)
      : null;
    this.latitude = config
      ? DomainModelHelper.ensureDataIsString(config.latitude)
      : null;
    this.locationCode = config
      ? DomainModelHelper.ensureDataIsString(config.locationCode)
      : null;
    this.locationConfidenceScore = config
      ? DomainModelHelper.ensureDataIsString(config.locationConfidenceScore)
      : null;
    this.locationType = config
      ? DomainModelHelper.ensureDataIsString(config.locationType)
      : null;
    this.longitude = config
      ? DomainModelHelper.ensureDataIsString(config.longitude)
      : null;
    this.matchConfidenceScore = config
      ? DomainModelHelper.ensureDataIsString(config.matchConfidenceScore)
      : null;
    this.matchType = config
      ? DomainModelHelper.ensureDataIsString(config.matchType)
      : null;
    this.postalCode = config
      ? DomainModelHelper.ensureDataIsString(config.postalCode)
      : null;
    this.postalCodeSuffix = config
      ? DomainModelHelper.ensureDataIsString(config.postalCodeSuffix)
      : null;
    this.stateCode = config
      ? DomainModelHelper.ensureDataIsString(config.stateCode)
      : null;
    this.timeZoneCode = config
      ? DomainModelHelper.ensureDataIsString(config.timeZoneCode)
      : null;
    this.timeZoneName = config
      ? DomainModelHelper.ensureDataIsString(config.timeZoneName)
      : null;
  }
}
