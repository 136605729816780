import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, map, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class Auth0TokenService {
  public accessToken: string;
  public idToken: string;
  constructor(public authService: AuthService) {}
  getTokens(): Observable<string[]> {
    return this.authService.isAuthenticated$.pipe(
      switchMap((isAuthenticated) => {
        if (isAuthenticated) {
          return this.authService.idTokenClaims$.pipe(
            switchMap((id) =>
              this.authService.getAccessTokenSilently().pipe(
                map((access) => {
                  this.accessToken = access;
                  this.idToken = id.__raw;
                  return [access, id.__raw];
                })
              )
            )
          );
        } else {
          return of([null, null]);
        }
      })
    );
  }
}
