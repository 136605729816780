import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'digital-platform-unsaved-changes-dialog',
  templateUrl: './unsaved-changes-dialog.component.html',
  styleUrls: ['./unsaved-changes-dialog.component.scss'],
})
export class UnsavedChangesDialogComponent {
  constructor(
    private readonly dialogRef: MatDialogRef<UnsavedChangesDialogComponent>
  ) {}

  public onCancel() {
    this.dialogRef.close(false);
  }

  public onOk() {
    this.dialogRef.close(true);
  }
}
