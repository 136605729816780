import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createStore, withProps, select } from '@ngneat/elf';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

import { CompanyName } from '@digital-platform/users/domain';

@Injectable({ providedIn: 'root' })
export class CompanyNameService {
  private store = createStore(
    { name: 'companyName' },
    withProps<CompanyName>({ data: null })
  );

  public companyNameList$: Observable<string[]>;

  constructor(private http: HttpClient) {
    this.companyNameList$ = this.store.pipe(select((state) => state.data));
  }

  private updateState(newState: { data: string[] }): void {
    this.store.update((state) => ({
      ...state,
      ...newState,
    }));
  }

  public getCompanyNameList() {
    this.getCompanyNameApi().subscribe((data) => {
      this.updateState(data);
    });
  }

  public getCompanyNameApi() {
    return this.http.get<{ data: string[] }>('/api/get-company-names');
  }

  public _filter(customerNames: string[], value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return customerNames?.filter((customer) =>
      this._normalizeValue(customer).includes(filterValue)
    );
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }
}
