import { Environment } from '@digital-platform/ries-hub-shared/domain';

export const environment: Environment = {
  production: true,
  googleMapsApiKey: 'AIzaSyDr7_k2RHSNlGR9Tg-1eFcu0NLooVwe1LU',
  adobeConfig: [
    {
      profile: '093fe7ed8cd0',
      key: 'cc7fefea60a2',
    },
  ],
  trackJsApplication: 'ries-hub-prod',
  auth0Domain: 'my.republicservices.com',
  auth0ClientId: 'sxWyLbyg5tevXulfclfw9vycyBbfwB7n',
  auth0audience: 'https://ries-hub-ui-api',
  cookieDomain: '.republicservices.com',
  rootUrl: 'https://www.republicservices.com',
  auraAppBarUrl:
    'https://aura-app-bar.aura-prod.awsext.repsrv.com/aura-app-bar.js',
};
