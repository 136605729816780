import { DomainModelHelper } from '@digital-platform/shared/domain';
import { UserInfo } from '../interfaces';

export class UserInfoModel implements UserInfo {
  readonly sub: string;
  readonly name: string;
  readonly email: string;
  readonly emailVerified: boolean;
  readonly title?: string;
  readonly phone?: string;
  readonly ext?: string;

  public static DEFAULT_CONFIG: UserInfo = {
    sub: null,
    name: null,
    email: null,
    emailVerified: false,
  };

  constructor(config?: UserInfo) {
    this.sub = config
      ? DomainModelHelper.ensureDataIsString(config.sub)
      : UserInfoModel.DEFAULT_CONFIG.sub;
    this.name = config
      ? DomainModelHelper.ensureDataIsString(config.name)
      : UserInfoModel.DEFAULT_CONFIG.name;
    this.email = config
      ? DomainModelHelper.ensureDataIsString(config.email)
      : UserInfoModel.DEFAULT_CONFIG.email;
    this.emailVerified = config
      ? DomainModelHelper.ensureDataIsBoolean(config.emailVerified)
      : UserInfoModel.DEFAULT_CONFIG.emailVerified;

    if (config.title !== undefined) {
      this.title = config
        ? DomainModelHelper.ensureDataIsString(config.title)
        : UserInfoModel.DEFAULT_CONFIG.title;
    }
    if (config.phone !== undefined) {
      this.phone = config
        ? DomainModelHelper.ensureDataIsString(config.phone)
        : UserInfoModel.DEFAULT_CONFIG.phone;
    }
    if (config.ext !== undefined) {
      this.ext = config
        ? DomainModelHelper.ensureDataIsString(config.ext)
        : UserInfoModel.DEFAULT_CONFIG.ext;
    }
  }
}
