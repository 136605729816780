import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createStore, withProps, select } from '@ngneat/elf';
import { Observable } from 'rxjs';
import { filter, finalize, map, tap } from 'rxjs/operators';

import {
  ErrorService,
  LoadingService,
} from '@digital-platform/shared/services';
import {
  UserInvitationResponse,
  UserInvite,
  confirmationEmailResponse,
} from '@digital-platform/users/domain';
import {
  UserAccessResponse,
  CreateAccountInvite,
  CreateAccountInviteData,
  CreateAccountInviteRequest,
  CreateAccountInviteResponse,
} from '@digital-platform/users/domain';

@Injectable({ providedIn: 'root' })
export class UserInviteService {
  public email$: Observable<CreateAccountInviteData['email']>;
  public inviteCode$: Observable<CreateAccountInviteData['inviteCode']>;
  public companyName$: Observable<CreateAccountInviteData['companyName']>;
  public loading$: Observable<boolean>;

  private store = createStore(
    { name: 'userInvite' },
    withProps<CreateAccountInviteData & { loading: boolean }>({
      email: null,
      inviteCode: null,
      companyName: null,
      roles: null,
      loading: false,
    })
  );

  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
    private errorService: ErrorService
  ) {
    this.email$ = this.store.pipe(
      select((state) => state.email),
      filter((email) => !!email)
    );
    this.inviteCode$ = this.store.pipe(
      select((state) => state.inviteCode),
      filter((inviteCode) => !!inviteCode)
    );
    this.companyName$ = this.store.pipe(
      select((state) => state.companyName),
      filter((companyName) => !!companyName)
    );

    this.loading$ = this.store.pipe(select((state) => state.loading));
  }

  public async generateCode(
    companyName: string,
    email: string,
    roles: string[]
  ) {
    const inviteCode = this.generateRandomString(8);

    //Customer role must always be included for legacy code
    if (
      roles?.includes('Manifest') &&
      !roles?.includes('Customer') &&
      !roles?.includes('Samsara')
    ) {
      roles.push('Customer');
    }
    if (
      roles?.includes('Samsara') &&
      !roles?.includes('Customer') &&
      !roles?.includes('Manifest')
    ) {
      roles.push('Customer');
    }
    if (
      roles?.includes('Samsara') &&
      roles?.includes('Manifest') &&
      !roles?.includes('Customer')
    ) {
      roles.push('Customer');
    }
    //default
    if (roles?.includes('')) {
      const index = roles.indexOf('');
      if (index > -1) {
        roles.splice(index, 1);
      }
      roles.push('Customer');
    }
    //console.log('Roles:::::', roles);
    const savedInvite = await this.saveInviteCode({
      inviteCode,
      companyName,
      email,
      roles,
    });

    console.log(savedInvite);
    return savedInvite;
  }

  public getInviteFromLink(linkCode: CreateAccountInvite['linkParam']) {
    this.loadingService.startLoading();
    this.http
      .post<{ data: CreateAccountInvite }>('/api/users/get-invite', {
        linkCode,
      })
      .pipe(map((response: { data: CreateAccountInvite }) => response.data))
      .subscribe({
        next: (response: CreateAccountInviteResponse) => {
          this.updateState(response);
          this.loadingService.stopLoading();
        },
        error: (error) => {
          this.errorService.raiseError(error.message);
        },
      });
  }

  public getInviteFromCode(
    email: CreateAccountInvite['email'],
    inviteCode: CreateAccountInvite['inviteCode']
  ) {
    this.loadingService.startLoading();
    this.http
      .post<{ data: CreateAccountInvite }>('/api/users/get-invite', {
        email,
        inviteCode,
      })
      .pipe(
        map((response: { data: CreateAccountInvite }) => {
          if (!response.data.companyName) {
            this.loadingService.stopLoading();
          }
          return response.data;
        }),
        filter((data) => !!data.companyName)
      )
      .subscribe((response: CreateAccountInviteResponse) => {
        this.updateState(response);
        this.loadingService.stopLoading();
      });
  }

  private updateState(
    newState: Partial<CreateAccountInviteData & { loading: boolean }>
  ): void {
    console.log(newState);
    this.store.update((state) => ({
      ...state,
      ...newState,
    }));
  }

  private generateRandomString(n: number): string {
    let randomString = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    for (let i = 0; i < n; i++) {
      randomString += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return randomString;
  }

  private async saveInviteCode(
    requestData: CreateAccountInviteRequest
  ): Promise<CreateAccountInviteResponse | void> {
    this.updateState({ loading: true });

    try {
      const inviteResponse = await this.http
        .post<{ data: CreateAccountInviteResponse }>(
          '/api/users/create-invite',
          {
            ...requestData,
          }
        )
        .pipe(
          map((response) => {
            console.log(response);
            if (response.data.error) {
              this.errorService.raiseError(response.data.error.message);
            }

            return response.data;
          })
        )
        .toPromise();
      this.updateState({ loading: false });
      return inviteResponse;
    } catch (error) {
      const wrappedError = new Error(
        `Error saving invite code ${JSON.stringify(error)}`
      );
      this.errorService.raiseError(wrappedError);
    }

    return;
  }

  public async getUserAccess(email: string, applicationName: string) {
    this.loadingService.startLoading();

    try {
      const response = await this.http
        .post<UserAccessResponse>('/api/auth/users/access-check', {
          email,
          applicationName,
        })
        .pipe(
          tap(() => {
            this.loadingService.stopLoading();
          })
        )
        .toPromise();
      return response;
    } catch (error: any) {
      this.loadingService.stopLoading();
      return {
        message: error?.error?.message,
        user: error?.error?.user || null,
      };
    }
  }

  public async inviteUser(step: string, userData: UserInvite) {
    switch (step) {
      case 'inviteNewUser':
        return this.inviteNewUser(userData);
      case 'inviteExistingUser':
        return this.inviteExistingUser(userData);
      default:
        throw new Error(`Invalid step: ${step}`);
    }
  }

  public async inviteNewUser(
    userData: UserInvite
  ): Promise<UserInvitationResponse> {
    this.loadingService.startLoading();

    try {
      const response = await this.http
        .post<UserInvitationResponse>('/api/auth/users/invite', userData)
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .toPromise();

      return response;
    } catch (error: unknown) {
      this.loadingService.stopLoading();
      this.errorService.raiseError(error as Error);
      throw error;
    }
  }

  public async inviteExistingUser(
    userData: UserInvite
  ): Promise<UserInvitationResponse> {
    this.loadingService.startLoading();

    try {
      const response = await this.http
        .put<UserInvitationResponse>('/api/auth/users/invite', userData)
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .toPromise();

      return response;
    } catch (error: unknown) {
      this.loadingService.stopLoading();
      this.errorService.raiseError(error as Error);
      throw error;
    }
  }

  public async confirmationEmail(email: string) {
    this.loadingService.startLoading();

    try {
      const response = await this.http
        .post<confirmationEmailResponse>('/api/users/confirmation-email', {
          email,
        })
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .toPromise();

      return response;
    } catch (error: unknown) {
      this.loadingService.stopLoading();
      this.errorService.raiseError(error as Error);
      throw error;
    }
  }
}
