import { ProfileInformation } from '../interfaces';

export class ProfileInformationModel implements ProfileInformation {
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly title: string;
  public readonly email: string;
  public readonly phoneNumber: string;
  public readonly phoneExtension: string;

  public static readonly DEFAULT_CONFIG: ProfileInformation = {
    firstName: '',
    lastName: '',
    title: '',
    email: '',
    phoneNumber: '',
    phoneExtension: '',
  };
}
