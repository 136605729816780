import { coerceArray } from '@angular/cdk/coercion';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, EMPTY, BehaviorSubject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

export const STATIC_FEATURE_FLAGS = new InjectionToken<FeatureFlags>(
  'featureFlags.static'
);

export interface FeatureFlags {
  [flag: string]: boolean;
}

const API = '/api/feature-flags';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  private flags: FeatureFlags = {};
  private flagsSubject = new BehaviorSubject<FeatureFlags>(null);

  public flags$ = this.flagsSubject.asObservable();

  constructor(
    private httpClient: HttpClient,
    @Inject(STATIC_FEATURE_FLAGS) private staticFeatureFlags: FeatureFlags
  ) {}

  public load(): Observable<void> {
    return this.httpClient.get<FeatureFlags>(API).pipe(
      switchMap((flags) => {
        if (flags) {
          this.flags = { ...this.flags, ...flags };
          this.flagsSubject.next(flags);
        }
        return EMPTY;
      }),
      catchError((e): Observable<any> => {
        console.error('Got error when loading feature flags', { error: e });
        this.flagsSubject.next(this.staticFeatureFlags);
        return EMPTY;
      })
    );
  }

  hasFlags(flags: string | string[]): boolean {
    return coerceArray(flags).every((current) => this.flags[current]);
  }

  getValue(key: string): string | number | boolean | undefined {
    return this.flags[key];
  }
}
