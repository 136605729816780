import {
  LOCATION_CONFIDENCE_LOWER_THRESHOLD,
  MATCH_CONFIDENCE_LOWER_THRESHOLD,
} from '../constants';
import { Address } from '../models/address';

export function formatAddress(address: Address, html = false): string {
  if (!address) {
    return '';
  }

  return `${address.addressLine1 ? address.addressLine1 : ''}${
    html ? '<br />' : ''
  }
    ${
      address.addressLine2
        ? `${address.addressLine2}${html ? '<br />' : ''}`
        : ''
    }
    ${
      address.addressLine3
        ? `${address.addressLine3}${html ? '<br />' : ''}`
        : ''
    }
    ${address.city ? address.city : ''}${
    address.city && (address.stateCode || address.postalCode) ? ',' : ''
  }
    ${address.stateCode ? address.stateCode : ''}
    ${address.postalCode ? address.postalCode : ''}${
    address.postalCode && address.postalCodeSuffix ? '-' : ''
  }${address.postalCodeSuffix ? address.postalCodeSuffix : ''}${
    html ? '<br />' : ''
  }
    ${address.country ? address.country : ''}`;
}

export function formatAddressSimple(address: Address): string {
  if (!address) {
    return '';
  }

  return `${address.addressLine1} ${
    address.addressLine2 ? address.addressLine2 + ' ' : ''
  } ${address.city ? address.city + ', ' : ''}${
    address.stateCode ? address.stateCode + ' ' : ''
  }${address.postalCode ? address.postalCode : ''}`;
}

/**
 * Formats postal code to match 5 digits or 5 digits plus additional 4 seperated by a hyphen
 * example output: 12345 or 12345-4321
 */
export function ensureValidPostalCode(
  postalCode: string,
  includeFullZipCode: boolean
) {
  return postalCode.indexOf('-') > 0 &&
    postalCode.split('-')[1].length === 4 &&
    includeFullZipCode
    ? postalCode
    : postalCode.slice(0, 5);
}

export function isMailable(
  matchConfidenceScore: string,
  locationConfidenceScore: string,
  recordType: string,
  mailability = true,
  allowPOBox = false,
  allowRRHighwayContract = false
) {
  let mailable = true;

  if (mailability) {
    mailable =
      parseInt(matchConfidenceScore, 10) >= MATCH_CONFIDENCE_LOWER_THRESHOLD &&
      parseInt(locationConfidenceScore, 10) >
        LOCATION_CONFIDENCE_LOWER_THRESHOLD;
  }

  const poBoxRequest = allowPOBox && recordType === 'PostOfficeBox';
  const rrHighwayRequest =
    allowRRHighwayContract && recordType === 'RRHighwayContract';

  return mailable || poBoxRequest || rrHighwayRequest;
}
