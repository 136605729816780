import { BaseError, ErrorOptions } from './base.error';

export class FileValidationError extends BaseError {
  constructor(message?: string, statusCodeOrOptions?: number | ErrorOptions) {
    let options = { statusCode: 415 };
    if (typeof statusCodeOrOptions === 'number') {
      options = {
        statusCode: statusCodeOrOptions
      };
    } else {
      options = {
        ...options,
        ...statusCodeOrOptions
      };
    }

    super(`Invalid File${message ? `: ${message}` : ''}`, options);
  }
}
