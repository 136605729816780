import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, IdToken } from '@auth0/auth0-angular';
import { createStore, withProps, select } from '@ngneat/elf';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import jwtDecode from 'jwt-decode';
import { combineLatest, of, Observable, EMPTY, throwError } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  skipWhile,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import { Environment } from '@digital-platform/ries-hub-shared/domain';
import { ENVIRONMENT } from '@digital-platform/ries-hub-shared/services';
import {
  DomainModelHelper,
  getCustomerAccessList,
} from '@digital-platform/shared/domain';
import {
  ErrorService,
  FeatureFlagsService,
} from '@digital-platform/shared/services';
import {
  CreateAccount,
  EmailNotVerifiedResponse,
  LoginResponse,
  LoginSession,
  ProfileSettings,
  User,
  UserModel,
} from '@digital-platform/users/domain';
import { LoginSessionService } from './login-session.service';

interface LoginSessionStore {
  session: LoginSession | null;
  /**
   * keeping a place in the store for the email address
   * entered on the create account form allows us to
   * populate the verify email page without having to log
   * them in and grab the email off the token
   *  */
  createAccountEmail: string | null;
  loading: boolean;
  message: string | null;
  accessToken?: string;
  idToken?: IdToken;
}

interface Tokens {
  access_token: string;
  id_token: string;
}
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class LoginSessionFacadeService {
  public loginSession$: Observable<LoginSession>;
  public loading$: Observable<boolean>;
  public error$: Observable<HttpErrorResponse>;
  public message$: Observable<string>;
  public createAccountEmail$: Observable<string>;
  public authentication$: Observable<[boolean, boolean]>;
  public tokens$: Observable<[string, IdToken]>;
  public accessList$: Observable<string[]>;
  public loggedIN: boolean;
  public universalLogin: boolean;
  public updateFlow = false;
  private environment: Environment = inject(ENVIRONMENT);

  private store = createStore(
    { name: 'loginSession' },
    withProps<LoginSessionStore>({
      session: null,
      createAccountEmail: null,
      loading: true,
      message: null,
    })
  );

  constructor(
    private service: LoginSessionService,
    private errorService: ErrorService,
    private router: Router,
    private authService: AuthService,
    private featureFlag: FeatureFlagsService
  ) {
    this.initialize();
  }

  public initialize() {
    const { store } = this;

    this.accessList$ = store.pipe(
      select((state) => state.session?.user?.access),
      map((access) => getCustomerAccessList(access))
    );

    this.loginSession$ = store.pipe(select((state) => state.session));
    this.loading$ = store.pipe(select((state) => state.loading));
    this.message$ = store.pipe(select((state) => state.message));
    this.createAccountEmail$ = store.pipe(
      select((state) => state.createAccountEmail),
      filter((email) => !!email)
    );

    this.featureFlag.flags$.pipe(untilDestroyed(this)).subscribe((flags) => {
      if (flags) {
        this.universalLogin = flags?.['isUniversalLogin'] ?? false;
      }

      if (this.universalLogin) {
        this.handleUniversalLogin();
      } else if (this.universalLogin === false) {
        this.handleNonUniversalLogin();
      }
    });
  }

  private handleUniversalLogin() {
    this.authentication$ = this.isUserAuthenticated$();
    this.tokens$ = this.getTokens$();

    this.authentication$
      .pipe(
        switchMap(([loading, isAuthenticated]) => {
          if (!loading && isAuthenticated) {
            return this.tokens$;
          }
          this.authService.loginWithRedirect();
          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe(this.handleAuthenticationResponse.bind(this));

    combineLatest([this.loading$, this.loginSession$, this.createAccountEmail$])
      .pipe(
        skipWhile(([loading, _, __]) => loading),
        map(([_, userSession, __]) => [
          (!!userSession && !!userSession.user) || false,
          userSession?.user?.emailVerified,
          __,
        ]),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(this.handleLoginStatus.bind(this));
  }

  private handleNonUniversalLogin() {
    this.service
      .getSession()
      .pipe(
        catchError((error) => {
          this.errorService.raiseError(error);

          return of({ user: null, error: '' });
        }),
        untilDestroyed(this)
      )
      .subscribe(this.handleSessionResponse.bind(this));

    combineLatest([this.loading$, this.loginSession$, this.createAccountEmail$])
      .pipe(
        skipWhile(([loading, _, __]) => loading),
        map(([_, session, __]) => [
          (!!session && !!session.user) || false,
          session?.user?.emailVerified,
          __,
        ]),
        distinctUntilChanged(),
        untilDestroyed(this)
      )

      .subscribe(this.handleLoginStatus.bind(this));
  }

  private async handleAuthenticationResponse([access_token, id_token]: [
    string,
    IdToken
  ]) {
    if (!this.updateFlow && access_token && id_token) {
      const session = await this.verifyToken(access_token, id_token);

      this.updateState({
        session: session,
        loading: false,
        message: session.error,
        createAccountEmail: session.user.emailVerified
          ? null
          : session.user.email,
      });
    }
    this.updateFlow = false;
  }

  private async handleSessionResponse(session: LoginSession) {
    this.updateState({
      session,
      loading: false,
      message: session.error,
      createAccountEmail: session?.user?.emailVerified
        ? null
        : session?.user?.email,
    });
  }

  private async handleLoginStatus(values: (string | boolean)[]) {
    const [loggedIn, emailVerified, createAccountEmail] = values as [
      boolean,
      boolean,
      string
    ];
    this.navigateBasedOnLoginStatus(
      loggedIn,
      emailVerified,
      createAccountEmail
    );
  }

  private navigateBasedOnLoginStatus(
    loggedIn: boolean,
    emailVerified: boolean,
    createAccountEmail: string
  ): void {
    if (loggedIn && emailVerified) {
      this.router.navigateByUrl('/');
    } else if (
      (loggedIn && !emailVerified) ||
      (!loggedIn && !!createAccountEmail)
    ) {
      this.router.navigateByUrl('/verify-email');
    } else {
      this.handleUniversal();
    }
  }

  private handleUniversal(): void {
    if (this.universalLogin) {
      this.authService.loginWithRedirect();
    } else {
      this.router.navigateByUrl('/login');
    }
  }

  public isUserAuthenticated$() {
    return combineLatest([
      this.authService.isLoading$,
      this.authService.isAuthenticated$,
    ]).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }
  public getTokens$() {
    return combineLatest([
      this.authService.getAccessTokenSilently(),
      this.authService.idTokenClaims$,
    ]).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  public refresh() {
    const { updateState, setTargetUrl, router, service, errorService } = this;
    if (this.universalLogin) {
      this.logout('Your session has timed out, please login.+-');
    } else {
      service
        .getSession()
        .pipe(
          catchError((error): Observable<LoginSession> => {
            errorService.raiseError(error);
            // set the url of the current route in case the user needs to be redirected to it
            // after logging in
            setTargetUrl(router.url);
            return of({
              user: null,
              error: 'Your session has timed out, please login.',
            });
          }),
          untilDestroyed(this)
        )
        .subscribe((session) => {
          this.updateState({
            session,
            loading: false,
            message: session.error,
            createAccountEmail: session?.user?.emailVerified
              ? null
              : session?.user?.email,
          });
        });
    }
  }

  public login(username: string, password: string) {
    const { emailNotVerified, clearOnboardingComplete, service } = this;

    this.updateState({ loading: true, message: null });

    service
      .login(username, password)
      .pipe(
        tap((response) => {
          if (emailNotVerified(response)) {
            this.updateState(response);
          }
        }),
        catchError((error) => {
          this.errorService.raiseError(error);

          return of({ user: null, error: '' });
        }),
        untilDestroyed(this)
      )
      .subscribe((session: LoginSession & LoginResponse) => {
        if ((session as any)?.createAccountEmail) {
          this.updateState({
            createAccountEmail: (session as any).createAccountEmail,
          });
          this.router.navigateByUrl('/verify-email');
        } else {
          this.updateState({
            session,
            loading: false,
            message: session?.error,
            createAccountEmail: null,
          });

          this.router.navigateByUrl('/');
          clearOnboardingComplete();
        }
      });
  }

  public logout(message?: string) {
    const { clearOnboardingComplete, service, errorService } = this;
    let options = {};
    options = {
      logoutParams: { returnTo: this.environment.rootUrl },
    };
    if (this.universalLogin) {
      this.authService.logout(options);
    } else {
      this.updateState({ loading: true });
      service
        .logout()
        .pipe(
          take(1),
          catchError((error) => {
            this.errorService.raiseError(error);

            return of({ user: null, error: '' });
          }),
          untilDestroyed(this)
        )
        .subscribe(() => {
          this.updateState({ session: null, loading: false, message });
          clearOnboardingComplete();
        }, errorService.raiseError);
    }
  }

  public update(userData: ProfileSettings) {
    const { service, errorService } = this;
    if (this.universalLogin) {
      this.updateState({ loading: true });
      service
        .updateUser(userData)
        .pipe(
          take(1),
          catchError((error) => {
            this.errorService.raiseError(error);
            return of({ user: null, error: '' });
          }),
          switchMap(() => this.authService.isLoading$),
          filter((loading) => !loading),
          switchMap(() => this.getAccessToken()),
          catchError((error) => {
            this.errorService.raiseError(error);
            return throwError(() => error);
          })
        )
        .subscribe(async (tokens) => {
          await this.updateStateAfterHandlingTokens(tokens);
        });
    } else {
      this.updateState({ loading: true });
      service
        .updateUser(userData)
        .pipe(
          take(1),
          catchError((error) => {
            this.errorService.raiseError(error);

            return of({ user: null, error: '' });
          })
        )
        .subscribe(
          (session) =>
            this.updateState({
              session,
              loading: false,
              message: session.error,
            }),
          errorService.raiseError
        );
    }
  }

  public universalRefresh(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getAccessToken()
        .pipe(
          catchError((error) => {
            reject(new Error('Error refreshing token'));
            return throwError(() => error);
          }),
          untilDestroyed(this)
        )
        .subscribe(async (tokens) => {
          await this.updateStateAfterHandlingTokens(tokens);
          resolve();
        });
    });
  }

  public forgotPassword(email: string) {
    const { service, errorService } = this;

    this.updateState({ loading: true });

    service
      .forgotPassword(email)
      .pipe(
        take(1),
        catchError((error) => {
          this.errorService.raiseError(error);

          return of({ user: null, error: '' });
        })
      )
      .subscribe(
        () => this.updateState({ session: null, loading: false }),
        errorService.raiseError
      );
  }

  public resetPassword(newPassword: string) {
    const { service, errorService } = this;

    this.updateState({ loading: true });

    service
      .resetPassword(newPassword)
      .pipe(
        take(1),
        catchError((error) => {
          this.errorService.raiseError(error);

          return of({ user: null, error: '' });
        })
      )
      .subscribe(() => {
        this.updateState({ session: null, loading: false });
      }, errorService.raiseError);
  }

  public createAccount(newUserData: CreateAccount) {
    const { service, errorService } = this;

    this.updateState({ loading: true });
    service.createAccount(newUserData).subscribe(() => {
      this.updateState({ loading: false });
      if (newUserData.inviteCode) {
        this.login(newUserData.email, newUserData.password);
      } else {
        this.updateState({
          loading: false,
          createAccountEmail: newUserData.email,
        });
        // this.sendVerificationEmail(newUserData.email, newUserData.password);
      }
    }, errorService.raiseError);
  }

  // public sendVerificationEmail(email: string, password: string) {
  //   const { service, errorService } = this;

  //   this.updateState({ loading: true });

  //   service
  //     .sendVerificationEmail(email, password)
  //     .pipe(
  //       take(1),
  //       catchError((error) => {
  //         this.errorService.raiseError(error);

  //         return of({ user: null, error: '' });
  //       })
  //     )
  //     .subscribe(() => {
  //       this.updateState({ session: null, loading: false });
  //     }, errorService.raiseError);
  // }

  public changePassword() {
    const { service, errorService } = this;

    this.updateState({ loading: true });

    service
      .changePassword()
      .pipe(
        take(1),
        catchError((error) => {
          this.errorService.raiseError(error);

          return of({ user: null, error: '' });
        }),
        untilDestroyed(this)
      )
      .subscribe(
        () => {
          if (!this.universalLogin) {
            this.updateState({
              session: null,
              loading: false,
            });
          } else {
            this.updateState({
              loading: false,
            });
          }
        },
        (error) => errorService.raiseError(error)
      );
  }

  public isLoggedIn(): Promise<boolean> {
    return combineLatest([this.loading$, this.loginSession$])
      .pipe(
        skipWhile(([loading, _]) => loading),
        take(1),
        map(([_, session]) => (!!session && !!session.user) || false)
      )
      .toPromise();
  }

  public isEmailVerified(): Promise<boolean> {
    const { loading$, createAccountEmail$, loginSession$ } = this;

    return combineLatest([loading$, createAccountEmail$, loginSession$])
      .pipe(
        skipWhile(([loading, _, __]) => loading),
        take(1),
        map(([_, email, session]) => (!session && email !== null) || false)
      )
      .toPromise();
  }

  public hasPermission(permission: string): Promise<boolean> {
    return combineLatest([this.loading$, this.loginSession$])
      .pipe(
        filter(([_, session]) => !!session),
        skipWhile(([loading, _]) => loading),
        take(1),
        map(
          ([_, session]) =>
            session.user?.permissions?.includes(permission) || false
        )
      )
      .toPromise();
  }

  public hasRole(role: string): Promise<boolean> {
    return combineLatest([this.loading$, this.loginSession$])
      .pipe(
        skipWhile(([loading, _]) => loading),
        take(1),
        map(
          ([_, session]) =>
            (!!session && session.user?.roles?.includes(role)) || false
        )
      )
      .toPromise();
  }

  public onboardingComplete(onboardingComplete: boolean) {
    const { service, errorService } = this;

    this.updateState({ loading: true });
    service
      .onboardingComplete(onboardingComplete)
      .pipe(
        take(1),
        catchError((error) => {
          this.errorService.raiseError(error);

          return of({ user: null, error: '' });
        })
      )
      .subscribe(
        (session) =>
          this.updateState({
            session,
            loading: false,
          }),
        errorService.raiseError
      );
  }

  public setOnboardingComplete(onboardingComplete: boolean) {
    sessionStorage.setItem('onboardingComplete', onboardingComplete.toString());
  }
  public getOnboardingComplete() {
    const onboardingComplete = sessionStorage.getItem('onboardingComplete');
    return onboardingComplete === 'true';
  }
  public clearOnboardingComplete() {
    sessionStorage.removeItem('onboardingComplete');
  }

  public clearMessage() {
    this.updateState({ message: null });
  }

  public setTargetUrl(targetUrl: string) {
    if (targetUrl !== '/login') {
      sessionStorage.setItem('targetUrl', targetUrl);
    }
  }
  public hasTargetUrl() {
    const targetUrl = sessionStorage.getItem('targetUrl');
    return targetUrl?.includes('/');
  }
  public getTargetUrl() {
    return sessionStorage.getItem('targetUrl');
  }
  public clearTargetUrl() {
    sessionStorage.removeItem('targetUrl');
  }

  private getAccessToken() {
    return this.authService
      .getAccessTokenSilently({
        cacheMode: 'off',
        detailedResponse: true,
      })
      .pipe(
        catchError((error) => {
          this.errorService.raiseError(error);
          this.logout('Your session has timed out, please login.');
          return throwError(() => error);
        })
      );
  }

  private async updateStateAfterHandlingTokens(tokens: Tokens) {
    this.updateFlow = true;

    const decodedIdToken = jwtDecode(tokens.id_token) as IdToken;
    const session = await this.verifyToken(tokens.access_token, decodedIdToken);

    this.updateState({
      session: session,
      loading: false,
      message: session.error,
      createAccountEmail: session?.user?.emailVerified
        ? null
        : session?.user?.email,
    });

    return session;
  }

  private updateState(newState: Partial<LoginSessionStore>): void {
    this.store.update((state) => ({
      ...state,
      ...newState,
    }));
  }

  private emailNotVerified(
    response: LoginResponse
  ): response is EmailNotVerifiedResponse {
    return 'createAccountEmail' in response;
  }

  private verifyToken(
    accessToken: string,
    idData: IdToken
  ): Promise<LoginSession> {
    const prefix = 'https://republicservices.com/'.trim();
    const accessData: any = jwtDecode(accessToken);
    // sanity checks
    if (accessData['iss'] !== idData['iss']) {
      throw new Error('Token issuer mismatch!');
    }
    if (accessData['sub'] !== idData['sub']) {
      throw new Error('Token sub mismatch!');
    }

    const appMetadata = accessData[`${prefix}app_metadata`];
    const userMetadata = accessData[`${prefix}user_metadata`];

    const sub = DomainModelHelper.ensureDataIsString(accessData?.sub);
    const roles = DomainModelHelper.ensureDataIsStringArray(appMetadata?.roles);
    const permissions = DomainModelHelper.ensureDataIsStringArray(
      accessData?.permissions
    );

    const access = DomainModelHelper.ensureDataIsStringArray(
      appMetadata?.access
    );
    const expiryNotification = DomainModelHelper.ensureDataIsNumberArray(
      userMetadata?.expiryNotification
    );
    const disposalSiteStates = DomainModelHelper.ensureDataIsStringArray(
      userMetadata?.disposalSiteStates
    );
    const title = DomainModelHelper.ensureDataIsString(userMetadata?.title);
    const company = DomainModelHelper.ensureDataIsString(userMetadata?.company);
    const phone = DomainModelHelper.ensureDataIsString(userMetadata?.phone);
    const ext = DomainModelHelper.ensureDataIsString(userMetadata?.ext);
    const name = DomainModelHelper.ensureDataIsString(idData?.name);
    const firstName = DomainModelHelper.ensureDataIsString(
      userMetadata?.first_name
    );
    const lastName = DomainModelHelper.ensureDataIsString(
      userMetadata?.last_name
    );
    const email = DomainModelHelper.ensureDataIsString(idData?.email);
    const emailVerified = DomainModelHelper.ensureDataIsBoolean(
      idData?.email_verified
    );
    const requirePasswordReset = DomainModelHelper.ensureDataIsBoolean(
      userMetadata?.requirePasswordReset
    );
    const onboardingComplete = DomainModelHelper.ensureDataIsBoolean(
      appMetadata?.onboardingComplete
    );
    const importGenerators = DomainModelHelper.ensureDataIsBoolean(
      appMetadata?.importGenerators
    );
    const importProfiles = DomainModelHelper.ensureDataIsBoolean(
      appMetadata?.importProfiles
    );

    const data: User = {
      sub,
      email,
      emailVerified,
      title,
      company,
      name,
      firstName,
      lastName,
      roles,
      permissions,
      access,
      expiryNotification,
      disposalSiteStates,
      phone,
      ext,
      requirePasswordReset,
      onboardingComplete,
      importGenerators,
      importProfiles,
      preferences: UserModel.DEFAULT_CONFIG.preferences, // unused?
      accounts: [],
    };

    return Promise.resolve(new LoginSession(data));
  }
}
