import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private notifier = new Subject<Error | string>();

  public error$ = this.notifier.asObservable();

  public raiseError(error: Error | string) {
    this.notifier.next(error);
    console.error(error);
  }

  public clearErrors() {
    this.notifier.next(null);
  }
}
