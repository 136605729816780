<div fxLayout="row" fxLayoutGap="10px" fxFlexFill>
  <div fxLayoutAlign="center center">
    <mat-icon>{{
      data?.custom === true ? 'check_circle' : 'error_outline'
    }}</mat-icon>
  </div>
  <div fxLayoutAlign="center center" style="padding-left: 5px" fxFlex="95">
    {{ data?.message }}
  </div>
  <div fxLayoutAlign="center center">
    <button
      mat-button
      color="{{ data?.custom === true ? '' : 'warn' }}"
      (click)="snackBarRef.dismiss()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
