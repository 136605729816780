import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class ExcelExportSerice {
  public exportTableToExcel(arr: any[], tableId: string, name?: string) {
    const { sheetName, fileName } = this.getFileName(name);
    const targetTableElm = document.getElementById(tableId);
    const wb = XLSX.utils.table_to_book(targetTableElm, <XLSX.Table2SheetOpts>{
      sheet: sheetName,
    });
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  public exportArrayToExcel(arr: any[], name?: string) {
    const { sheetName, fileName } = this.getFileName(name);

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(arr);
    ws['!cols'] = this.fitToColumn(arr);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  getFileName = (name: string) => {
    const timeSpan = new Date().toISOString().slice(0, 10);
    const sheetName = name || 'waste-disposal-report';
    const fileName = `${sheetName}-${timeSpan}`;
    return {
      sheetName,
      fileName,
    };
  };

  fitToColumn(array: any[]) {
    const columns: any[] = [];
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      for (const [key, value] of Object.entries(element)) {
        const findColumn = columns.find((c) => c?.key === key);

        if (findColumn && findColumn.width < this.findMaxLength(key, value)) {
          findColumn.width = this.findMaxLength(key, value);
        } else if (!findColumn) {
          columns.push({
            key,
            width: this.findMaxLength(key, value),
          });
        }
      }
    }

    const columnWidths = columns.map((m: any) => ({
      wch: m.width,
    }));

    return columnWidths;
  }

  private findMaxLength(key: string, value: unknown) {
    return Math.max(key.toString().length, value.toString().length);
  }
}
