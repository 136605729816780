<mat-dialog-content>
  <h2>Do you want to save your progress?</h2>

  <mat-icon-button
    mat-icon-button
    cdkFocusInitial
    class="close"
    aria-label="Close dialog"
    color="accent"
    [mat-dialog-close]=""
  >
    <mat-icon>close</mat-icon>
  </mat-icon-button>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button (click)="onCancel()">No</button>
  <button mat-flat-button color="accent" class="remove-button" (click)="onOk()">
    Yes
  </button>
</mat-dialog-actions>
