// these mimeTypes are never allowed past the validator and will always fail
export const mimeNeverlist = [
  'application/x-httpd-php',
  'application/x-csh',
  'application/vnd.ms-fontobject',
  'application/vnd.apple.installer+xml',
  'application/x-shockwave-flash',
  'application/xhtml+xml',
  'application/x-msdownload',
  'application/x-ms-installer',
  'application/x-elf',
  'application/x-sh',
  'application/java-archive',
  'application/x-bytecode.python',
  'application/java',
  'application/x-java-class',
  'text/x-perl',
  'text/x-python',
  'text/javascript',
  'text/ecmascript',
  'text/asp',
  'text/x-c'
];
