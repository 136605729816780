import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { NotificationMessage } from '@digital-platform/shared/domain';
import { GlobalAlertComponent } from './global-alert.component';

@Injectable({
  providedIn: 'root',
})
export class GlobalAlertService {
  private globalAlerts: NotificationMessage[] = [];
  private noAlerts$ = new Subject<void>();

  constructor(
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private router: Router
  ) {}

  public openSnackBar(
    message: string,
    action: string,
    duration?: number
  ): void {
    const config = new MatSnackBarConfig();

    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    if (action === 'success') {
      config.panelClass = ['success-alert'];
      config.duration = duration ? duration : 2000;
      config.data = { message, custom: true };
    } else {
      config.panelClass = ['global-alert'];
      config.data = { message };
    }

    this._snackBar
      .openFromComponent(GlobalAlertComponent, config)
      .afterDismissed()
      .subscribe(async () => {
        await this.removeDismissedMessage(message);

        if (this.globalAlerts.length < 1) {
          this.noAlerts$.next();
        }
      });
  }

  public async checkForAlerts() {
    this.globalAlerts = await this.http
      .get<{ data: NotificationMessage[] }>('/api/content/global-alert/current')
      .pipe(map((res) => res.data))
      .toPromise();
    this.openMatchingAlert(this.router.url);
    this.watchRoutes();
  }

  private openMatchingAlert(url: string) {
    const matches = this.filterNotifications(url);
    if (matches.length > 0) {
      this.openSnackBar(matches[0].message, 'X');
    }
  }

  private watchRoutes() {
    this.router.events
      .pipe(
        takeUntil(this.noAlerts$),
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        const route = event.url;

        this.openMatchingAlert(route);
      });
  }

  private async removeDismissedMessage(message: string): Promise<void> {
    // temporary measure. the filter is being wonkey and right now we have a max of
    // 1 alert anyways, so I will fix the filter when we add multiple alerts functionality
    this.globalAlerts = [];
    this.noAlerts$.next();
    // this.globalAlerts.filter((alert) => alert.message !== message);
  }

  private filterNotifications(url: string): NotificationMessage[] {
    return this.globalAlerts.filter((alert) =>
      this.testUrl(alert.pattern, url)
    );
  }

  private testUrl(pattern: string, url: string): boolean {
    const regEx = new RegExp(pattern);

    return regEx.test(this.router.url);
  }
}
