import { CreateAccount } from '../interfaces/create-account.interface';

export class CreateAccountModel implements CreateAccount {
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly title: string;
  public readonly companyName: string;
  public readonly email: string;
  public readonly phoneNumber: string;
  public readonly password: string;
  public readonly agreeTermsOfService: boolean;

  public static readonly DEFAULT_CONFIG: CreateAccount = {
    firstName: '',
    lastName: '',
    title: '',
    companyName: '',
    email: '',
    phoneNumber: '',
    password: '',
    agreeTermsOfService: false,
  };
}
