import { DomainModelHelper } from '../utils/DomainModelHelper';
import { Entity } from './entity';
import { EntityMeta } from './entity-meta';
import { Persistable } from './persistable';
import { PersistableEntity } from './persistable-entity';

export abstract class EntityBase implements Entity, Persistable {
  readonly id: string;
  readonly createdBy?: string;
  readonly createdDate?: Date;
  readonly lastModifiedBy?: string;
  readonly lastModifiedDate?: Date;
  readonly meta?: EntityMeta;

  static readonly DEFAULT_CONFIG: Entity = {
    id: '',
    meta: {},
  };

  constructor(config: PersistableEntity) {
    this.id = config ? DomainModelHelper.ensureDataIsString(config.id) : null;
    this.createdDate = config
      ? DomainModelHelper.ensureDataIsDate(config.createdDate)
      : null;
    this.createdBy = config
      ? DomainModelHelper.ensureDataIsString(config.createdBy)
      : null;
    this.lastModifiedDate = config
      ? DomainModelHelper.ensureDataIsDate(config.lastModifiedDate)
      : null;
    this.lastModifiedBy = config
      ? DomainModelHelper.ensureDataIsString(config.lastModifiedBy)
      : null;
  }
}
