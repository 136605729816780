import { Injectable, Injector } from '@angular/core';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';

import { LoginSessionFacadeService } from './login-session-facade.service';

@Injectable({
  providedIn: 'root',
})
export class Auth0SessionManagementService {
  private facadeLogin: LoginSessionFacadeService;

  constructor(private auth0Service: Auth0Service, private injector: Injector) {}

  checkAndUpdateIdToken(): Promise<void> {
    return new Promise((resolve) => {
      this.auth0Service.isAuthenticated$.subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          this.auth0Service.idTokenClaims$.subscribe((idToken) => {
            const tokenExpirationTime = (idToken?.exp ?? 1) * 1000;
            const tokenIssueTime = (idToken?.iat ?? 0) * 1000;

            // Get the current time
            const now = Date.now();

            // Calculate the token's total lifetime
            const tokenLifetime = tokenExpirationTime - tokenIssueTime;

            // Calculate 75% of the token's total lifetime
            const seventyFivePercentTime =
              tokenIssueTime + tokenLifetime * 0.75;

            // If the current time has passed 75% of the token's lifetime, refresh the token
            if (now >= seventyFivePercentTime) {
              // Get the LoginSessionFacadeService instance from the injector
              this.facadeLogin = this.injector.get(LoginSessionFacadeService);
              this.facadeLogin
                .universalRefresh()
                .then(() => resolve())
                .catch(() => {
                  throw new Error('Error refreshing token');
                });
            } else {
              resolve(); // Token not expired, resolve the promise
            }
          });
        } else {
          resolve();
        }
      });
    });
  }
}
