import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { FeatureFlagsService } from '@digital-platform/shared/services';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

function loadScript(url: string, type: string = 'text/javascript'): void {
  const isFound = !!document.head.querySelector(`script[src="${url}"]`);

  if (!isFound) {
    const scriptElement = document.createElement('script');
    scriptElement.async = true;
    scriptElement.type = type;
    scriptElement.src = url;
    document.head.appendChild(scriptElement);
  }
}

function loadAdobeLaunch(profile: string, key: string): void {
  const adobeLaunchUrl = `//assets.adobedtm.com/ba7cd524c0f9/${profile}/launch-${key}.min.js`;
  loadScript(adobeLaunchUrl);
}

function loadAdobeConfigs(): void {
  if (environment.adobeConfig?.length) {
    environment.adobeConfig.forEach((adobe) => {
      loadAdobeLaunch(adobe.profile, adobe.key);
    });
  }
}

function loadAuraAppBar(): void {
  const auraAppBarUrl = environment.auraAppBarUrl;
  loadScript(auraAppBarUrl, 'module');
}

function checkFeatureFlagAndLoadAppBar(
  featureFlagsService: FeatureFlagsService
): void {
  const isFeatureEnabled = featureFlagsService.getValue('isUniversalLogin');
  if (isFeatureEnabled) {
    loadAuraAppBar();
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((moduleRef) => {
    const injector = moduleRef.injector;
    const featureFlagsService = injector.get(FeatureFlagsService);

    loadAdobeConfigs();
    checkFeatureFlagAndLoadAppBar(featureFlagsService);
  })
  .catch((err) => console.error(err));
