import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Data,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { NextFunction } from 'lambda-api';
import { combineLatest, Observable } from 'rxjs';
import { first, map, skipWhile } from 'rxjs/operators';

import { FeatureFlagsService } from '@digital-platform/shared/services';
import { LoginSession } from '@digital-platform/users/domain';
import { LoginSessionFacadeService } from '../login-session-facade.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  public isUniversalLogin: boolean;
  constructor(
    private facade: LoginSessionFacadeService,
    private featureFacade: FeatureFlagsService,
    private router: Router,
    private authService: AuthService
  ) {
    this.featureFacade.flags$.subscribe((flags) => {
      if (flags) {
        this.isUniversalLogin = flags?.['isUniversalLogin'] ?? false;
      }
    });
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const isLoggedIn = await this.facade.isLoggedIn();
    // if the user isn't logged in we can just redirect them right away
    if (!isLoggedIn) {
      // set the url of the current route in case the user needs to be redirected to it
      // after logging in
      this.facade.setTargetUrl(state.url);
    }

    const routeRoles = this.getRouteRoles(next.data);
    const routePermissions = this.getRoutePermissions(next.data);

    if (routeRoles.length > 0) {
      // check roles if any (older method)
      return this.checkRouteRoles(routeRoles, next);
    } else if (routePermissions.length > 0) {
      // check permissions if any (newer method)
      return this.checkRoutePermissions(routePermissions);
    }

    // we already know they're logged in and have a verified email,
    // and there are no roles or permissions required for the route
    return true;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(next, state);
  }

  private getRouteRoles(data: Data): string[] {
    const routeRoles = [];

    if (data?.role) {
      routeRoles.push(data.role);
    }

    if (data?.roles) {
      routeRoles.push(...data.roles);
    }

    return routeRoles;
  }

  private getRoutePermissions(data: Data): string[] {
    const routePermissions = [];

    if (data?.permission) {
      routePermissions.push(data.permission);
    }

    if (data?.permissions) {
      routePermissions.push(...data.permissions);
    }

    return routePermissions;
  }

  private async checkRouteRoles(
    routeRoles: string[],
    next: ActivatedRouteSnapshot
  ): Promise<boolean | UrlTree> {
    return combineLatest([this.facade.loading$, this.facade.loginSession$])
      .pipe(
        skipWhile(([loading, _]) => loading),
        map(([_, loginSession]) =>
          this.handleRoleCheck(loginSession, routeRoles, next)
        ),
        first()
      )
      .toPromise();
  }

  private handleRoleCheck(
    loginSession: LoginSession,
    routeRoles: string[],
    next: any
  ) {
    if (loginSession?.user) {
      const userRoles = loginSession.user.roles || [];

      if (routeRoles.length > 0 && userRoles.length > 0) {
        const { hasAnyRole, hasAllRoles } = this.checkUserRoles(
          userRoles,
          routeRoles
        );

        // if the route wants all the roles to match and the user has all required roles
        // or any of the roles match
        if ((!next.data?.allRoles && hasAnyRole) || hasAllRoles) {
          return true;
        }
      }

      return this.router.parseUrl('/access-denied');
    }

    if (this.isUniversalLogin) {
      this.authService.loginWithRedirect();
      return false;
    } else if (this.isUniversalLogin === false) {
      return this.router.parseUrl('/login');
    }
    return false;
  }

  private checkUserRoles(userRoles: string[], routeRoles: string[]) {
    let hasAnyRole = false;
    let hasAllRoles = true;

    for (const routeRole of routeRoles) {
      hasAnyRole = hasAnyRole || userRoles.includes(routeRole);
      hasAllRoles = hasAllRoles && userRoles.includes(routeRole);
    }

    return { hasAnyRole, hasAllRoles };
  }

  private async checkRoutePermissions(
    routePermissions: string[]
  ): Promise<boolean | UrlTree> {
    if (!(await this.facade.isLoggedIn())) {
      if (this.isUniversalLogin) {
        this.authService.loginWithRedirect();
        return false;
      } else if (this.isUniversalLogin === false) {
        return this.router.parseUrl('/login');
      }
    }

    for (const permission of routePermissions) {
      if (await this.facade.hasPermission(permission)) {
        return true;
      }
    }

    return this.router.parseUrl('/access-denied');
  }
}
