import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private notifier = new BehaviorSubject<boolean>(false);
  private loadingCount = 0;

  public get loading$(): Observable<boolean> {
    // the `delay` prevents changed after checked errors for consumers
    return this.notifier.asObservable().pipe(delay(0));
  }

  // TODO: Idea - This could return some kind of unique id that we can track to reduce the state & track?
  public startLoading() {
    this.loadingCount++;
    this.notifier.next(true);
  }

  public stopLoading() {
    this.loadingCount--;
    if (this.loadingCount <= 0) {
      this.notifier.next(false);
      this.loadingCount = 0;
    }
  }
}
