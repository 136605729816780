import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

import { staticFeatureFlags } from '@digital-platform/shared/domain';
import { SharedUiMaterialModule } from '@digital-platform/shared/ui-material';
import { STATIC_FEATURE_FLAGS } from './feature-flags/feature-flags.service';
import { GlobalAlertComponent } from './global-alert/global-alert.component';
import { GlobalAlertService } from './global-alert/global-alert.service';
import { UnsavedChangesDialogComponent } from './unsaved-changes-dialog/unsaved-changes-dialog.component';

@NgModule({
  imports: [CommonModule, SharedUiMaterialModule, FlexLayoutModule],
  declarations: [UnsavedChangesDialogComponent, GlobalAlertComponent],
  exports: [MatIconModule],
  providers: [
    {
      provide: STATIC_FEATURE_FLAGS,
      useValue: staticFeatureFlags,
    },
    GlobalAlertService,
  ],
})
export class SharedServicesModule {}
