export const staticFeatureFlags: { [flag: string]: boolean } = {
  'create-account-enabled': true,
  'profile-disposal-sites-states-enabled': true,
  'service-agreement-use-ml': true,
  'use-wp-wizard-v2': true,
  'wp-wiz-deepwell-enabled': false,
  'wp-wiz-express-enabled': true,
  'wp-wiz-hazmat-enabled': false,
  'wp-admin-coordinator-filter-enabled': true,
  isUniversalLogin: true,
};
