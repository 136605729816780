import { DomainModelHelper } from '../utils/DomainModelHelper';
import { Contact } from './contact';
import { PersistableBase } from './persistable-base';

export class ContactModel extends PersistableBase implements Contact {
  readonly id: string | null;
  readonly email: string | null;
  readonly fax: string | null;
  readonly name: string;
  readonly phone: string;
  readonly phoneExt: string | null;

  public static readonly DEFAULT_CONFIG: Contact = {
    id: null,
    name: '',
    email: null,
    phone: '',
    phoneExt: null,
    fax: null,
  };

  constructor(config: Contact) {
    super(config);
    this.id = config ? DomainModelHelper.ensureDataIsString(config.id) : null;
    this.name = config
      ? DomainModelHelper.ensureDataIsString(config.name)
      : null;
    this.email = config
      ? DomainModelHelper.ensureDataIsString(config.email)
      : null;
    this.phone = config
      ? DomainModelHelper.ensureDataIsString(config.phone)
      : null;
    this.phoneExt = config
      ? DomainModelHelper.ensureDataIsString(config.phoneExt)
      : null;
    this.fax = config ? DomainModelHelper.ensureDataIsString(config.fax) : null;
  }
}
