import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { MetricsData } from '@digital-platform/shared/domain';

@Injectable({
  providedIn: 'root',
})
export class UserMetricsService {
  constructor(private httpClient: HttpClient) {}

  public getUsermetrics(): Observable<MetricsData> {
    const url = `api/users/metrics`;

    return this.httpClient
      .get<MetricsData>(url)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(error);

      switch (error.status) {
        case 403:
          return throwError(
            'Access denied while loading Waste Profile metrics, please try again later or contact Republic Services'
          );
      }
    }

    let message = 'Something bad happened; please try again later.';

    if (error.error.message) {
      message = error.error.message;
    }

    // Return an observable with a user-facing error message.
    return throwError(message);
  }
}
