interface CharacterType {
  readonly type: string;
  readonly label: string;
  readonly description: string;
  readonly examples: string;
  readonly pattern: RegExp;
}

export interface PasswordRequirements {
  readonly minLength: number;
  readonly minCharacterTypes: number;
  readonly characterTypes: CharacterType[];
}

export const PASSWORD_REQUIREMENTS: PasswordRequirements = {
  minLength: 8,
  minCharacterTypes: 3,
  characterTypes: [
    {
      type: 'lowercase',
      label: 'Lower case',
      description: 'Lower case letters (a-z)',
      examples: 'abc',
      pattern: /[a-z]/,
    },
    {
      type: 'uppercase',
      label: 'Upper case',
      description: 'Upper case (A-Z)',
      examples: 'ABC',
      pattern: /[A-Z]/,
    },
    {
      type: 'number',
      label: 'Number',
      description: 'Numbers (i.e. 0-9)',
      examples: '123',
      pattern: /[0-9]/,
    },
    {
      type: 'symbol',
      label: 'Symbol',
      description: 'Special Characters (e.g. !@#$%^&*)',
      examples: '@#$',
      pattern: /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
    },
  ],
};
