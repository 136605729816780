import { DomainModelHelper } from '../utils/DomainModelHelper';
import { Address } from './address';
import { AddressModel } from './address-model';
import { Billing } from './billing';
import { Contact } from './contact';
import { ContactModel } from './contact-model';
import { PersistableBase } from './persistable-base';

export class BillingModel extends PersistableBase implements Billing {
  readonly id?: string;
  readonly name: string;
  readonly contact: Contact;
  readonly address: Address;
  readonly notes: string;

  static readonly DEFAULT_CONFIG: Billing = {
    name: '',
    notes: '',
    contact: {
      name: '',
      email: '',
      phone: '',
      phoneExt: '',
      fax: '',
    },
    address: {
      addressLine1: '',
      postalCode: '',
    },
  };

  constructor(config: Billing) {
    super(config);

    this.id = config ? DomainModelHelper.ensureDataIsString(config.id) : null;
    this.name = config
      ? DomainModelHelper.ensureDataIsString(config.name)
      : null;
    this.contact = config ? this.createContact(config.contact) : null;
    this.address = config ? this.createAddress(config.address) : null;
    this.notes = config
      ? DomainModelHelper.ensureDataIsString(config.notes)
      : null;
  }

  protected createAddress(addressConfig: Address) {
    return addressConfig ? new AddressModel(addressConfig) : null;
  }

  protected createContact(contactConfig: Contact) {
    return contactConfig ? new ContactModel(contactConfig) : null;
  }
}
