import { User } from '../interfaces';
import { UserModel } from './user.model';

interface BaseAccount {
  active?: boolean; // placeholder prop to avoid empty interface
}

interface InfoProAccount extends BaseAccount {
  readonly infopro: string;
}

interface TidalTankAccount extends BaseAccount {
  readonly tidaltank: string;
}

interface SouthernTankAccount extends BaseAccount {
  readonly southerntank: string;
}

export type Account = InfoProAccount | TidalTankAccount | SouthernTankAccount;

export class Auth0UserInfo {
  readonly sub: string;
  readonly nickname: string;
  readonly name: string;
  readonly picture: string;
  readonly email: string;
  readonly email_verified: boolean;
  public readonly user_metadata: Auth0UserMetadata;
}
export interface Auth0UserMetadata {
  readonly first_name: string;
  readonly last_name: string;
  readonly expiryNotification: number[];
  readonly disposalSiteStates: string[];
  readonly title: string;
  readonly company: string;
  readonly phone: string;
  readonly ext: string;
  readonly requirePasswordReset: boolean;
}

export interface LoginTokenUserData {
  readonly sub: string;
  readonly name: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly emailVerified: boolean;
  readonly title: string;
  readonly company: string;
  readonly roles: string[];
  readonly permissions: string[];
  readonly access: string[];
  readonly expiryNotification: number[];
  readonly disposalSiteStates: string[];
  readonly requirePasswordReset: boolean;
}

const DEFAULT_USER_CONFIG: LoginTokenUserData = {
  sub: null,
  email: '',
  emailVerified: false,
  name: '',
  firstName: '',
  lastName: '',
  title: '',
  company: '',
  roles: [],
  permissions: [],
  access: [],
  expiryNotification: [],
  disposalSiteStates: [],
  requirePasswordReset: false,
};
export class LoginToken {
  public readonly user: {
    readonly sub: string;
    readonly title: string;
    readonly company: string;
    readonly roles: string[];
    readonly permissions: string[];
    readonly access: string[];
    readonly expiryNotification: number[];
    readonly disposalSiteStates: string[];
    readonly name: string;
    readonly firstName?: string;
    readonly lastName?: string;
    readonly email: string;
    readonly emailVerified: boolean;
    readonly requirePasswordReset: boolean;
    readonly phone?: string;
  };
  public readonly error: string;

  constructor(
    userData: Partial<LoginTokenUserData> = DEFAULT_USER_CONFIG,
    error = ''
  ) {
    this.user = {
      ...DEFAULT_USER_CONFIG,
      ...userData,
    };
    this.error = error;
  }
}

export class LoginSession extends LoginToken {
  public readonly user: User;
  public readonly error: string;

  constructor(data: Partial<User> = UserModel.DEFAULT_CONFIG, error = '') {
    let userData = {
      ...UserModel.DEFAULT_CONFIG,
      ...data,
    };

    // If we're explicitly given null, keep it null
    if (data === null) {
      userData = null;
    }

    super(userData, error);
    this.user = userData;
    this.error = error;
  }
}

export class Auth0Token {
  readonly access_token: string;
  readonly id_token: string;
  readonly refresh_token?: string;
  readonly scope: string;
  readonly expires_in: number;
}

export class TokenData {
  readonly token: string;
  readonly id: string;
  readonly refresh?: string;
  readonly scope: string;
  readonly expires: number;
  constructor(
    tokenData: {
      token: string;
      id: string;
      refresh?: string;
      scope: string;
      expires: number;
    } = {
      token: '',
      id: '',
      scope: '',
      expires: 0,
    }
  ) {
    this.token = tokenData.token;
    this.id = tokenData.id;
    this.scope = tokenData.scope;
    this.expires = tokenData.expires;

    if (tokenData.refresh) {
      this.refresh = tokenData.refresh;
    }
  }
}

// auth0 create user interface
export interface CreateAuth0User {
  readonly user_id?: string;
  readonly email: string;
  readonly email_verified: boolean;
  readonly name: string;
  readonly connection: string;
  readonly password: string;

  readonly user_metadata: {
    readonly expiryNotification: number[];
    readonly disposalSiteStates: string[];
    readonly title: string;
    readonly company: string;
    readonly phone: string;
    readonly requirePasswordReset: boolean;
  };
  readonly app_metadata: {
    readonly roles: string[];
    readonly permissions?: string[];
    readonly access: string[];
  };
}

// this is what the auth0 management api returns for a user
// the user id is called user_id, not sub
export class Auth0User {
  readonly user_id: string;
  readonly nickname: string;
  readonly name: string;
  readonly picture: string;
  readonly email: string;
  readonly email_verified: boolean;
  public readonly user_metadata: Auth0UserMetadata;
}

export interface Auth0UserMetadata {
  readonly first_name: string;
  readonly last_name: string;
  readonly expiryNotification: number[];
  readonly disposalSiteStates: string[];
  readonly title: string;
  readonly company: string;
  readonly phone: string;
  readonly ext: string;
  readonly requirePasswordReset: boolean;
}

export interface VerificationEmailRequest {
  readonly email: string;
  readonly password: string;
}
export interface VerificationEmailSentResponse {
  readonly type: string;
  readonly status: string;
}
