import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, from, take } from 'rxjs';

import { FeatureFlagsService } from '@digital-platform/shared/services';
import { Auth0TokenService } from '../auth0-token.service';
import { Auth0SessionManagementService } from '../auth0SessionManagementService';

const AUTHORIZATION_HEADER = 'authorization';
const ID_TOKEN_HEADER = 'idtoken';
const ATTACHMENTS_URL = /ries-hub-api-.*-attachments/;
@UntilDestroy()
@Injectable()
export class AddTokenInterceptor implements HttpInterceptor {
  private facadeToken: Auth0TokenService;
  private featureFlags: FeatureFlagsService;
  private sessionManagement: Auth0SessionManagementService;
  private accessToken: string;
  private idToken: string;
  constructor(
    private injector: Injector,
    private featureFlagService: FeatureFlagsService
  ) {}

  public intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return from(this.handle(req, next));
  }

  private async handle(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Promise<HttpEvent<unknown>> {
    let clonedReq = req;
    const flags = await this.featureFlagService.flags$
      .pipe(take(1))
      .toPromise();

    if (flags?.['isUniversalLogin'] === true) {
      this.facadeToken = this.injector.get(Auth0TokenService);
      this.sessionManagement = this.injector.get(Auth0SessionManagementService);
      await this.sessionManagement.checkAndUpdateIdToken();
      await this.subscribeToTokens();
      clonedReq = this.setRequestHeaders(req, clonedReq);
    }

    return next.handle(clonedReq).toPromise();
  }
  private setRequestHeaders(
    req: HttpRequest<unknown>,
    clonedReq: HttpRequest<unknown>
  ) {
    if (!ATTACHMENTS_URL.test(req.url) && this.idToken && this.accessToken) {
      clonedReq = req.clone({
        headers: req.headers
          .set(AUTHORIZATION_HEADER, `Bearer ${this.accessToken}`)
          .set(ID_TOKEN_HEADER, this.idToken),
      });
    }
    return clonedReq;
  }

  catch(error: unknown) {
    throw error;
  }

  subscribeToTokens(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.facadeToken
        .getTokens()
        .pipe(untilDestroyed(this))
        .subscribe(
          ([accessToken, idToken]) => {
            this.accessToken = accessToken;
            this.idToken = idToken;
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
