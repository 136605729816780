import { FileValidationError } from '../errors/file-validation.error';
import { mimeNeverlist } from './mime-neverlist';

const defaultErrorMessage = 'The provided file is not allowed.';

export function matchesList(
  mimeType: string,
  list: string[]
): string | undefined {
  return list.find((listedType) => {
    const regex = new RegExp(
      listedType.replace(/[\\.\\+]/g, '\\$&').replace('*', '.*'),
      'i'
    );
    return regex.test(mimeType);
  });
}

export function checkBlacklist(
  mimeType: string,
  mimeBlacklist: { [mimeType: string]: string }
) {
  const matched = matchesList(mimeType, Object.keys(mimeBlacklist));
  if (matched) {
    throw new FileValidationError(
      mimeBlacklist[matched] || defaultErrorMessage
    );
  }
}

export function checkWhitelist(
  mimeType: string,
  mimeWhitelist: string[],
  mimeBlacklist: { [mimeType: string]: string } = {}
) {
  if (mimeWhitelist.length === 0) {
    return;
  }

  const matched = matchesList(mimeType, mimeWhitelist);
  if (!matched) {
    // check if there's a blackliist message for this type, else use the default
    const blacklistMatch = matchesList(mimeType, Object.keys(mimeBlacklist));
    throw new FileValidationError(
      mimeBlacklist[blacklistMatch] || defaultErrorMessage
    );
  }
}

export function checkNeverList(mimeType: string) {
  const matched = matchesList(mimeType, mimeNeverlist);
  if (matched) {
    throw new FileValidationError(defaultErrorMessage);
  }
}
