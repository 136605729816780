/* eslint-disable @typescript-eslint/no-explicit-any */
export interface ErrorOptions {
  statusCode?: number;
  originalError?: Error | unknown;
  context?: any;
}

export class BaseError extends Error {
  public statusCode: number;
  public originalError?: Error | unknown;
  public context?: any;

  constructor(message: string, options?: ErrorOptions) {
    super(message);

    const { statusCode, originalError, context } = {
      statusCode: 500,
      ...(options || {}),
    };

    this.statusCode = statusCode;

    if (originalError !== undefined) {
      this.originalError = originalError;
    }

    if (context !== undefined) {
      this.context = context;
    }
  }
}
