import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { combineLatest, BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  bufferCount,
  filter,
  map,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { WizardTocItem } from '@digital-platform/shared/domain';

// basic concept inspired by https://itnext.io/simple-wizard-stepper-for-your-angular-web-apps-31b9edaebd9a

// Add this service to the `providers` section of your parent component
// so each wizard has its own instance of this service
@Injectable()
export class WizardStepsService implements OnDestroy {
  private steps: BehaviorSubject<WizardTocItem[]> = new BehaviorSubject<
    WizardTocItem[]
  >([]);
  private currentStep: BehaviorSubject<WizardTocItem> =
    new BehaviorSubject<WizardTocItem>(null);

  private destroyed$ = new Subject<void>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly logger: NGXLogger
  ) {
    this.handleRouterState();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public get steps$(): Observable<WizardTocItem[]> {
    // TODO: this tectnically creates a new observable each time, is this acceptable?
    return this.steps.asObservable();
  }

  public get currentStep$(): Observable<WizardTocItem> {
    // TODO: this tectnically creates a new observable each time, is this acceptable?
    return this.currentStep.asObservable();
  }

  public get percentComplete$(): Observable<number> {
    return combineLatest([this.steps, this.currentStep]).pipe(
      filter(
        ([steps, currentStep]) => steps && currentStep && steps.length > 0
      ),
      map(
        ([steps, currentStep]) =>
          ((this.findStepIndex(currentStep) + 1) / steps.length) * 100
      )
    );
  }

  public setSteps(value: WizardTocItem[]) {
    this.steps.next(value);
  }

  public moveToStep(step: WizardTocItem) {
    if (!step) {
      const first = this.steps.value[0];

      if (!first) {
        this.logger.error('No wizard step provided and no first step found!');
      }

      this.currentStep.next(first);
      return;
    }

    this.currentStep.next(step);
  }

  public moveToNextStep(): void {
    const currentIndex = this.findStepIndex(this.currentStep.value);
    const next = currentIndex + 1;

    if (next < this.steps.value.length) {
      this.moveToStep(this.steps.value[next]);
    }
  }

  public moveToPreviousStep(): void {
    const currentIndex = this.findStepIndex(this.currentStep.value);
    const previous = currentIndex - 1;

    if (previous >= 0) {
      this.moveToStep(this.steps.value[previous]);
    }
  }

  private findStepIndex(step: WizardTocItem): number {
    return this.steps.value.findIndex(
      (value) => value.itemRouterLink === step.itemRouterLink
    );
  }

  private findStepForRouterUrl(url: string): WizardTocItem {
    return this.steps.value.find(
      (step) => this.parseUrl(step.itemRouterLink) === url
    );
  }

  // parses routerLink compativle commands into a strong of an absolute url
  private parseUrl(url: any[] | string | null | undefined): string {
    if (url) {
      if (Array.isArray(url)) {
        return this.router
          .createUrlTree(url, { relativeTo: this.route })
          .toString();
      }

      return this.router
        .createUrlTree([url], { relativeTo: this.route })
        .toString();
    }

    return this.router.createUrlTree([], { relativeTo: this.route }).toString();
  }

  // handles maintaining state parity between our state here and angular's router
  private handleRouterState() {
    combineLatest([
      this.router.events.pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        startWith(this.router)
      ),
      this.currentStep$,
      this.steps$,
    ])
      .pipe(
        map(([event, step, steps]) => {
          const routeUrl = event.url;
          const stepUrl = step ? this.parseUrl(step.itemRouterLink) : null;
          const routeStep = this.findStepForRouterUrl(routeUrl);

          return { routeUrl, stepUrl, steps, routeStep, step };
        }),
        bufferCount(2, 1), // lets us get the last 2 values on each tick
        filter(([_, { steps }]) => steps.length > 0),
        tap(([prev, current]) => this.logger.debug({ prev, current })),
        takeUntil(this.destroyed$)
      )
      .subscribe(([prev, current]) => {
        // route and step is out of sync
        if (current.routeUrl !== current.stepUrl) {
          const routeChanged = prev.routeUrl !== current.routeUrl;
          const stepChanged = prev.stepUrl !== current.stepUrl;

          if (routeChanged && !stepChanged) {
            this.logger.info(
              'update step state to match route url',
              current.routeUrl
            );
            this.moveToStep(current.routeStep);
            return;
          } else if (stepChanged && !routeChanged) {
            this.logger.info(
              'update route to match step state url',
              current.stepUrl
            );
            this.router.navigateByUrl(current.stepUrl);
            return;
          } else if (current.stepUrl === null && !!current.routeStep) {
            this.logger.info('no current step state, loading step from route', {
              step: current.routeStep,
            });
            this.moveToStep(current.routeStep);
            return;
          } else if (current.stepUrl === null || !current.routeStep) {
            const step = current.steps[0];
            this.logger.info('no current step state, loading initial step', {
              step,
            });
            // use the router and circle back to update state so we can control
            // the browser history stack
            this.router.navigateByUrl(this.parseUrl(step.itemRouterLink), {
              replaceUrl: true,
            });
            return;
          }

          this.logger.error('Route/Wizard State mismatch and cannot update', {
            prev,
            current,
          });
        }
      });
  }
}
