import {
  HttpClientJsonpModule,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Inject, NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
// import { DynamicInputModule } from 'dynamic-input-width';
import { AuthModule } from '@auth0/auth0-angular';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { TrackJS } from 'trackjs';

import { Environment } from '@digital-platform/ries-hub-shared/domain';
import { ENVIRONMENT } from '@digital-platform/ries-hub-shared/services';
import { appInitializerProviders as sharedServicesInitializerProviders } from '@digital-platform/shared/services';
import { httpInterceptorProviders as sharedServicesInterceptorProviders } from '@digital-platform/shared/services';
import {
  AddTokenInterceptor,
  httpInterceptorProviders as usersServicesInterceptorProviders,
} from '@digital-platform/users/services';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@digital-platform/ries-hub-ui/shell').then(
        (m) => m.RiesHubUiShellModule
      ),
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    MatIconModule,
    BrowserAnimationsModule,
    // DynamicInputModule,
    LoggerModule.forRoot({
      level: environment.production
        ? NgxLoggerLevel.ERROR
        : NgxLoggerLevel.DEBUG,
      enableSourceMaps: true,
    }),
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
    }),

    AuthModule.forRoot({
      domain: environment.auth0Domain,
      clientId: environment.auth0ClientId,
      cookieDomain: environment.cookieDomain,
      useRefreshTokens: false,
      useRefreshTokensFallback: false,
      cacheLocation: 'localstorage',
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.auth0audience,
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddTokenInterceptor,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', floatLabel: 'always' },
    },
    {
      provide: 'googleMapsApiKey',
      useValue: environment.googleMapsApiKey,
    },
    {
      provide: 'TRACK_JS',
      useValue: {
        application: environment.trackJsApplication,
        token: '258f9f87a3da4ccfb0cb10f1821c5ce8',
      },
    },
    {
      provide: ENVIRONMENT,
      useFactory: () => Environment.parse(environment),
    },

    usersServicesInterceptorProviders,
    sharedServicesInterceptorProviders,
    sharedServicesInitializerProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    @Inject('TRACK_JS') private trackJs: { application: string; token: string }
  ) {
    if (this.trackJs.application !== 'none') {
      TrackJS.install({
        token: trackJs.token,
        application: trackJs.application,
      });
    }
  }
}
