import { Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

@Component({
  selector: 'digital-platform-global-alert',
  templateUrl: './global-alert.component.html',
  styleUrls: ['./global-alert.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GlobalAlertComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBarRef: MatSnackBarRef<GlobalAlertComponent>
  ) {}
}
