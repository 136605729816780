/* "Barrel" of App Initializers */

import { APP_INITIALIZER } from '@angular/core';

import { staticFeatureFlags } from '@digital-platform/shared/domain';
import {
  FeatureFlagsService,
  STATIC_FEATURE_FLAGS,
} from './feature-flags/feature-flags.service';

export function preloadFeatureFlags(featureFlagsService: FeatureFlagsService) {
  return function () {
    return featureFlagsService.load().toPromise();
  };
}

export const appInitializerProviders = [
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: preloadFeatureFlags,
    deps: [FeatureFlagsService],
  },
  {
    provide: STATIC_FEATURE_FLAGS,
    useValue: staticFeatureFlags,
  },
];
