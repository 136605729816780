import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LoginSessionFacadeService } from '../login-session-facade.service';

@Injectable()
export class TokenExpiredInterceptor implements HttpInterceptor {
  private facadeLogin: LoginSessionFacadeService;

  constructor(private injector: Injector) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // don't intercept requests to the user api, this breaks the login system
    if (
      request.url.startsWith('/api/users') ||
      request.url.startsWith('/api/auth')
    ) {
      return next.handle(request);
    }

    this.facadeLogin = this.injector.get(LoginSessionFacadeService);
    return next.handle(request).pipe(
      catchError((err: any) => {
        if (
          err instanceof HttpErrorResponse &&
          (err.status === 401 || err.status === 403)
        ) {
          this.facadeLogin.refresh();
        }
        return throwError(err);
      })
    );
  }
}
