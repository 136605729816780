import { DomainModelHelper } from '@digital-platform/shared/domain';
import { User } from '../interfaces';
import { Account } from './login-session.model';

export class UserModel implements User {
  public readonly sub: string;
  public readonly name: string;
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly email: string;
  public readonly emailVerified: boolean;
  public readonly title: string;
  public readonly company: string;
  public readonly roles: string[];
  public readonly permissions: string[];
  public readonly preferences: {
    readonly notifications: string[];
    readonly color: string;
  };
  public readonly access: string[];
  public readonly expiryNotification: number[];
  public readonly disposalSiteStates: string[];
  public readonly accounts: Account[];
  public readonly phone: string;
  public readonly ext: string;
  public requirePasswordReset: boolean;

  static readonly DEFAULT_CONFIG: User = {
    sub: '',
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    emailVerified: false,
    title: '',
    company: '',
    roles: [],
    permissions: [],
    preferences: {
      notifications: [],
      color: '',
    },
    access: [],
    expiryNotification: [],
    disposalSiteStates: [],
    accounts: [],
    phone: '',
    ext: '',
    requirePasswordReset: false,
  };

  constructor(config?: User) {
    this.sub = this.assignConfigValue(
      config?.sub,
      UserModel.DEFAULT_CONFIG.sub,
      DomainModelHelper.ensureDataIsString
    );
    this.name = this.assignConfigValue(
      config?.name,
      UserModel.DEFAULT_CONFIG.name,
      DomainModelHelper.ensureDataIsString
    );
    this.firstName = this.assignConfigValue(
      config?.firstName,
      UserModel.DEFAULT_CONFIG.firstName,
      DomainModelHelper.ensureDataIsString
    );
    this.lastName = this.assignConfigValue(
      config?.lastName,
      UserModel.DEFAULT_CONFIG.lastName,
      DomainModelHelper.ensureDataIsString
    );
    this.email = this.assignConfigValue(
      config?.email,
      UserModel.DEFAULT_CONFIG.email,
      DomainModelHelper.ensureDataIsString
    );
    this.emailVerified = this.assignConfigValue(
      config?.emailVerified,
      UserModel.DEFAULT_CONFIG.emailVerified,
      DomainModelHelper.ensureDataIsBoolean
    );
    this.title = this.assignConfigValue(
      config?.title,
      UserModel.DEFAULT_CONFIG.title,
      DomainModelHelper.ensureDataIsString
    );
    this.company = this.assignConfigValue(
      config?.company,
      UserModel.DEFAULT_CONFIG.company,
      DomainModelHelper.ensureDataIsString
    );
    this.roles = this.assignConfigValue(
      config?.roles,
      UserModel.DEFAULT_CONFIG.roles,
      DomainModelHelper.ensureDataIsStringArray
    );
    this.permissions = this.assignConfigValue(
      config?.permissions,
      UserModel.DEFAULT_CONFIG.permissions,
      DomainModelHelper.ensureDataIsStringArray
    );
    this.preferences = this.assignConfigValue(
      config?.preferences,
      UserModel.DEFAULT_CONFIG.preferences,
      this.createPreferences
    );
    this.access = this.assignConfigValue(
      config?.access,
      UserModel.DEFAULT_CONFIG.access,
      DomainModelHelper.ensureDataIsStringArray
    );
    this.expiryNotification = this.assignConfigValue(
      config?.expiryNotification,
      UserModel.DEFAULT_CONFIG.expiryNotification,
      DomainModelHelper.ensureDataIsNumberArray
    );
    this.disposalSiteStates = this.assignConfigValue(
      config?.disposalSiteStates,
      UserModel.DEFAULT_CONFIG.disposalSiteStates,
      DomainModelHelper.ensureDataIsStringArray
    );
    this.accounts = this.assignConfigValue(
      config?.accounts,
      UserModel.DEFAULT_CONFIG.accounts,
      this.createAccounts
    );
    this.phone = this.assignConfigValue(
      config?.phone,
      UserModel.DEFAULT_CONFIG.phone,
      DomainModelHelper.ensureDataIsString
    );
    this.ext = this.assignConfigValue(
      config?.ext,
      UserModel.DEFAULT_CONFIG.ext,
      DomainModelHelper.ensureDataIsString
    );
    this.requirePasswordReset = this.assignConfigValue(
      config?.requirePasswordReset,
      UserModel.DEFAULT_CONFIG.requirePasswordReset,
      DomainModelHelper.ensureDataIsBoolean
    );
  }

  protected assignConfigValue<T>(
    configValue: T,
    defaultValue: T,
    ensureDataIsType: (data: T) => T
  ): T {
    return configValue ? ensureDataIsType(configValue) : defaultValue;
  }

  protected createPreferences(
    preferencesConfig: User['preferences']
  ): User['preferences'] {
    return {
      notifications: DomainModelHelper.ensureDataIsStringArray(
        preferencesConfig?.notifications
      ),
      color: DomainModelHelper.ensureDataIsString(preferencesConfig?.color),
    };
  }

  protected createAccounts(accountsConfig: Account[]): Account[] {
    if (!Array.isArray(accountsConfig)) {
      return [];
    }

    return accountsConfig
      .map((accountConfig: any) => {
        let active: boolean;
        if (accountConfig.active !== undefined) {
          active = DomainModelHelper.ensureDataIsBoolean(accountConfig.active);
        }

        if (accountConfig.infopro !== undefined) {
          return {
            active,
            infopro: DomainModelHelper.ensureDataIsString(
              accountConfig.infopro
            ),
          };
        } else if (accountConfig.tidaltank !== undefined) {
          return {
            active,
            tidaltank: DomainModelHelper.ensureDataIsString(
              accountConfig.tidaltank
            ),
          };
        } else if (accountConfig.southerntank !== undefined) {
          return {
            active,
            southerntank: DomainModelHelper.ensureDataIsString(
              accountConfig.southerntank
            ),
          };
        }
        return null;
      })
      .filter((account) => !!account);
  }
}
