import { DomainModelHelper } from '../utils/DomainModelHelper';
import { Persistable } from './persistable';

export abstract class PersistableBase implements Persistable {
  readonly createdBy?: string;
  readonly createdDate?: Date;
  readonly lastModifiedBy?: string;
  readonly lastModifiedDate?: Date;

  constructor(config: Persistable) {
    this.createdDate = config
      ? DomainModelHelper.ensureDataIsDate(config.createdDate)
      : null;
    this.createdBy = config
      ? DomainModelHelper.ensureDataIsString(config.createdBy)
      : null;
    this.lastModifiedDate = config
      ? DomainModelHelper.ensureDataIsDate(config.lastModifiedDate)
      : null;
    this.lastModifiedBy = config
      ? DomainModelHelper.ensureDataIsString(config.lastModifiedBy)
      : null;
  }
}
