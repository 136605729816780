export function booleanToYesNo(value: boolean): 'yes' | 'no' {
  if (value === true) {
    return 'yes';
  }

  if (value === false) {
    return 'no';
  }

  return value;
}

export function yesNoToBoolean(value: string): boolean {
  if (!value) {
    return null;
  }

  const normalized = value?.toLowerCase();
  if (normalized === 'yes') {
    return true;
  }

  if (normalized === 'no') {
    return false;
  }

  return null;
}
