import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class CustomIconsService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      `profile`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/profile-icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `truck`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/truck-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `dumpster`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/dumpster-icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `box`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/box-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `plus`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/plus-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `folder`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/folder-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `edit`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/edit-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `truck`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/truck-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `dumpster`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/dumpster-icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `box`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/box-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `plus`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/plus-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `folder`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/folder-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `renew`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/renew-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `duplicate`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/duplicate-icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `arrow-left`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/arrow-left-icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `arrow-right`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/arrow-right-icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `expand`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/expand-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `compress`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/tiles-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `upload`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/upload-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `download`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/download-icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `check`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/check-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `book`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/book-closed-icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `reject`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/close-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `recurring`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/recurring-icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `case`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/case-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `sanitary`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/sanitary-icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `drill`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/drill-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `robot`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/robot-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `txt-file`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/txt-file-icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `drum`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/drum-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `menu`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/menu-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `downloadFill`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/download-fill-icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `save`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/save_icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `settings`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/settings-icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `contact-mail`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/contact-mail-icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `sales-rep`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/sales-rep-icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `analyst`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/analyst-icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `coordinator`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/coordinator-icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `landfill-add`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/landfill-add-icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `landfill-edit`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/landfill-edit-icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `update-user`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/customer-user-flat-icon.svg`
      )
    );
  }
}
