import { z } from 'zod';

const AdobeConfigObj = z.object({
  profile: z.string(),
  key: z.string(),
});

export const Environment = z.object({
  production: z.boolean(),
  trackJsApplication: z.string().optional(),
  googleMapsApiKey: z.string().optional(),
  adobeConfig: z.array(AdobeConfigObj).optional(),
  auth0Domain: z.string().optional(),
  auth0ClientId: z.string().optional(),
  auth0audience: z.string().optional(),
  cookieDomain: z.string().optional(),
  rootUrl: z.string().optional(),
  auraAppBarUrl: z.string().optional(),
});

export type Environment = z.infer<typeof Environment>;
