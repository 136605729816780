export function getCustomerAccessList(access: string[]): string[] {
  access = access || [];

  return access
    .filter(entry => entry.startsWith('cust:'))
    .map(entry => entry.replace('cust:', ''));
}

export function getDivisionAccessList(access: string[]): string[] {
  access = access || [];

  return access
    .filter(entry => entry.startsWith('div:'))
    .map(entry => entry.replace('div:', ''));
}
