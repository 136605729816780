import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

import { FeatureFlagsService } from '@digital-platform/shared/services';
import { LoginSessionFacadeService } from '../login-session-facade.service';

@Injectable({
  providedIn: 'root',
})
export class UniversalLoginGuard implements CanActivate {
  public isUniversalLogin: boolean;
  constructor(
    private facade: LoginSessionFacadeService,
    private featureFacade: FeatureFlagsService,
    private router: Router,
    private authService: AuthService
  ) {
    this.isUniversalLogin = Boolean(
      this.featureFacade.getValue('isUniversalLogin')
    );
  }
  //Remove this after universal login
  async canActivate() {
    const isLoggedIn = await this.facade.isLoggedIn();

    if (isLoggedIn && this.isUniversalLogin) {
      this.router.navigate(['/dashboard']);
      return false;
    } else {
      if (this.isUniversalLogin) {
        this.authService.loginWithRedirect();
        return false;
      } else {
        return true;
      }
    }
  }
}
