import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  ActivationEnd,
  NavigationEnd,
  Router,
} from '@angular/router';
import { of, EMPTY } from 'rxjs';
import {
  defaultIfEmpty,
  expand,
  filter,
  map,
  mergeMap,
  takeWhile,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private pageName: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title
  ) {
    this.watchRouterForTitle();
  }

  watchRouterForTitle() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute), // switches the stream to the current activated route
        map((route) => {
          // traverse down the route tree to the last activated route
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap((leafRoute) =>
          of(leafRoute)
            .pipe(
              // gather the parents of the route (expand is recursive, EMPTY is our end signal)
              expand((route) => (route.parent ? of(route.parent) : EMPTY)),
              // get the route data for each route
              mergeMap((route) => route.data),
              // get the title if present from each data
              map((data) => data?.title || ''),
              // take the first one we find, as it will be the deepest child route's title
              takeWhile((title) => !!title),
              // if we don't find one, emit empty so prior titles don't stick around
              defaultIfEmpty('')
            )
            .pipe()
        )
      )
      .subscribe((title) => {
        this.setTitle(title);
      });
  }

  setTitle(title: string) {
    this.title.setTitle(`${title}${title !== '' ? ' | ' : ''}Republic Account`);
  }

  setPageName(pageName: string) {
    this.pageName = pageName;
  }
  getPageName() {
    return this.pageName;
  }
}
