import { ElementRef } from '@angular/core';
import * as _ from 'lodash';

export function scrollOnError(el: ElementRef, errorControls: string[]) {
  const errorControl = _.first(errorControls);
  let formControl = '';
  let invalidControl;

  if (errorControl) {
    const hierarchy = errorControl.split('.');
    const matches = errorControl.match(/\d+/);
    if (matches && matches.length > 0) {
      const regex = matches[0];
      const getPosition = Number(regex);
      const indexOf = hierarchy.indexOf(regex);
      formControl = hierarchy[indexOf + 1];
      invalidControl =
        el.nativeElement.querySelectorAll(`[formControlName=${formControl}]`)[
          getPosition
        ] ||
        el.nativeElement.querySelectorAll(
          `[formControlName=${formControl}]`
        )[0];
    } else {
      switch (true) {
        case hierarchy.length === 2:
          formControl = hierarchy[0];
          break;
        case hierarchy.length === 3:
          formControl = hierarchy[1];
          break;
        case hierarchy.length === 4:
          formControl = hierarchy[2];
          break;
        default:
          break;
      }
      invalidControl = el.nativeElement.querySelector(
        `[formControlName=${formControl}]`
      );
    }

    if (invalidControl) {
      window.scroll({
        top: offSetFromTop(invalidControl),
        left: 0,
        behavior: 'smooth',
      });
      invalidControl.focus();
    }
  }
}

export function offSetFromTop(control: HTMLElement) {
  const labelOffset = 100;
  return control?.getBoundingClientRect().top + window.scrollY - labelOffset;
}
