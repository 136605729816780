import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { LoginSessionFacadeService } from '../login-session-facade.service';

@Injectable({
  providedIn: 'root',
})
export class CreateAccountGuard implements CanActivate, CanActivateChild {
  constructor(
    private facade: LoginSessionFacadeService,
    private router: Router
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const isLoggedIn = await this.facade.isLoggedIn();
    // if the user is logged in we can redirect them to home page
    if (isLoggedIn) {
      this.router.navigateByUrl('/');
      return false;
    } else {
      return true;
    }
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(next, state);
  }
}
