import { StateCode } from '../interfaces';

export const allStateCodes: StateCode[] = [
  // US
  { code: 'AK', country: 'US', label: 'Alaska' },
  { code: 'AL', country: 'US', label: 'Alabama' },
  { code: 'AR', country: 'US', label: 'Arkansas' },
  { code: 'AZ', country: 'US', label: 'Arizona' },
  { code: 'CA', country: 'US', label: 'California' },
  { code: 'CO', country: 'US', label: 'Colorado' },
  { code: 'CT', country: 'US', label: 'Connecticut' },
  { code: 'DC', country: 'US', label: 'District of Columbia' },
  { code: 'DE', country: 'US', label: 'Delaware' },
  { code: 'FL', country: 'US', label: 'Florida' },
  { code: 'GA', country: 'US', label: 'Georgia' },
  { code: 'HI', country: 'US', label: 'Hawaii' },
  { code: 'IA', country: 'US', label: 'Iowa' },
  { code: 'ID', country: 'US', label: 'Idaho' },
  { code: 'IL', country: 'US', label: 'Illinois' },
  { code: 'IN', country: 'US', label: 'Indiana' },
  { code: 'KS', country: 'US', label: 'Kansas' },
  { code: 'KY', country: 'US', label: 'Kentucky' },
  { code: 'LA', country: 'US', label: 'Louisiana' },
  { code: 'MA', country: 'US', label: 'Massachusetts' },
  { code: 'MD', country: 'US', label: 'Maryland' },
  { code: 'ME', country: 'US', label: 'Maine' },
  { code: 'MI', country: 'US', label: 'Michigan' },
  { code: 'MN', country: 'US', label: 'Minnesota' },
  { code: 'MO', country: 'US', label: 'Missouri' },
  { code: 'MS', country: 'US', label: 'Mississippi' },
  { code: 'MT', country: 'US', label: 'Montana' },
  { code: 'NC', country: 'US', label: 'North Carolina' },
  { code: 'ND', country: 'US', label: 'North Dakota' },
  { code: 'NE', country: 'US', label: 'Nebraska' },
  { code: 'NH', country: 'US', label: 'New Hampshire' },
  { code: 'NJ', country: 'US', label: 'New Jersey' },
  { code: 'NM', country: 'US', label: 'New Mexico' },
  { code: 'NV', country: 'US', label: 'Nevada' },
  { code: 'NY', country: 'US', label: 'New York' },
  { code: 'OH', country: 'US', label: 'Ohio' },
  { code: 'OK', country: 'US', label: 'Oklahoma' },
  { code: 'OR', country: 'US', label: 'Oregon' },
  { code: 'PA', country: 'US', label: 'Pennsylvania' },
  { code: 'RI', country: 'US', label: 'Rhode Island' },
  { code: 'SC', country: 'US', label: 'South Carolina' },
  { code: 'SD', country: 'US', label: 'South Dakota' },
  { code: 'TN', country: 'US', label: 'Tennessee' },
  { code: 'TX', country: 'US', label: 'Texas' },
  { code: 'UT', country: 'US', label: 'Utah' },
  { code: 'VA', country: 'US', label: 'Virginia' },
  { code: 'VT', country: 'US', label: 'Vermont' },
  { code: 'WA', country: 'US', label: 'Washington' },
  { code: 'WI', country: 'US', label: 'Wisconsin' },
  { code: 'WV', country: 'US', label: 'West Virginia' },
  { code: 'WY', country: 'US', label: 'Wyoming' },
  { code: 'PR', country: 'US', label: 'Puerto Rico' },

  // Canada
  { code: 'AB', country: 'CA', label: 'Alberta' },
  { code: 'BC', country: 'CA', label: 'British Columbia' },
  { code: 'MB', country: 'CA', label: 'Manitoba' },
  { code: 'NB', country: 'CA', label: 'New Brunswick ' },
  { code: 'NL', country: 'CA', label: 'Newfoundland and Labrador' },
  { code: 'NS', country: 'CA', label: 'Nova Scotia' },
  { code: 'ON', country: 'CA', label: 'Ontario ' },
  { code: 'PE', country: 'CA', label: 'Prince Edward Island' },
  { code: 'QC', country: 'CA', label: 'Quebec' },
  { code: 'SK', country: 'CA', label: 'Saskatchewan' },

  // Mexico
  { code: 'AGU', country: 'MX', label: 'Aguascalientes' },
  { code: 'BCN', country: 'MX', label: 'Baja California' },
  { code: 'BCS', country: 'MX', label: 'Baja California Sur' },
  { code: 'CAM', country: 'MX', label: 'Campeche' },
  { code: 'CHP', country: 'MX', label: 'Chiapas' },
  { code: 'CHH', country: 'MX', label: 'Chihuahua' },
  { code: 'COA', country: 'MX', label: 'Coahuila ' },
  { code: 'COL', country: 'MX', label: 'Colima' },
  { code: 'CMX', country: 'MX', label: 'Mexico City' },
  { code: 'DUR', country: 'MX', label: 'Durango' },
  { code: 'GUA', country: 'MX', label: 'Guanajuato' },
  { code: 'GRO', country: 'MX', label: 'Guerrero' },
  { code: 'HID', country: 'MX', label: 'Hidalgo' },
  { code: 'JAL', country: 'MX', label: 'Jalisco' },
  { code: 'MEX', country: 'MX', label: 'México' },
  { code: 'MIC', country: 'MX', label: 'Michoacán' },
  { code: 'MOR', country: 'MX', label: 'Morelos' },
  { code: 'NAY', country: 'MX', label: 'Nayarit' },
  { code: 'NLE', country: 'MX', label: 'Nuevo León' },
  { code: 'OAX', country: 'MX', label: 'Oaxaca' },
  { code: 'PUE', country: 'MX', label: 'Puebla' },
  { code: 'QUE', country: 'MX', label: 'Querétaro' },
  { code: 'ROO', country: 'MX', label: 'Quintana Roo' },
  { code: 'SLP', country: 'MX', label: 'San Luis Potosí' },
  { code: 'SIN', country: 'MX', label: 'Sinaloa' },
  { code: 'SON', country: 'MX', label: 'Sonora' },
  { code: 'TAB', country: 'MX', label: 'Tabasco' },
  { code: 'TAM', country: 'MX', label: 'Tamaulipas' },
  { code: 'TLA', country: 'MX', label: 'Tlaxcala ' },
  { code: 'VER', country: 'MX', label: 'Veracruz' },
  { code: 'YUC', country: 'MX', label: 'Yucatán' },
  { code: 'ZAC', country: 'MX', label: 'Zacatecas' },
];
