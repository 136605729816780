import { isValid, parseJSON, toDate } from 'date-fns';

/* eslint-disable @typescript-eslint/no-explicit-any */
export class DomainModelHelper {
  public static ensureDataIsNumber(value: any): number {
    const convertedValue = parseFloat(value);
    const result: number = isNaN(convertedValue) ? null : convertedValue;
    return result;
  }

  public static ensureDataIsDate(
    someData: string | number | Date
  ): Date | null {
    let createdDate: Date = null;

    if (someData !== undefined) {
      if (typeof someData === 'string') {
        createdDate = parseJSON(someData);
      } else if (typeof someData === 'number') {
        createdDate = toDate(someData);
      } else if (someData instanceof Date) {
        createdDate = toDate(someData);
      }
    }

    return isValid(createdDate) ? createdDate : null;
  }

  public static ensureDataIsString(someData: any): string {
    let result: string = null;

    if (someData !== undefined) {
      if (typeof someData === 'string') {
        result = someData;
      } else if (typeof someData === 'number') {
        result = `${someData}`;
      }
    }
    return result;
  }

  static ensureDataIsBoolean(someData: any): boolean {
    let result: boolean = null;

    if (someData !== undefined) {
      if (typeof someData === 'boolean') {
        result = someData;
      }
    }

    return result;
  }

  public static ensureDataIsStringArray(
    someData: any,
    options: { filterFalsy?: boolean } = {}
  ): string[] {
    let result: string[] = [];

    if (someData !== undefined) {
      if (typeof someData === 'object' && Array.isArray(someData)) {
        result = someData
          .map(DomainModelHelper.ensureDataIsString)
          .filter((item) => (options.filterFalsy ? !!item : true));
      }
    }

    return result;
  }

  public static ensureDataIsNumberArray(
    someData: any,
    options: { filterFalsy?: boolean } = {}
  ): number[] {
    let result: number[] = [];

    if (!Array.isArray(someData)) someData = [someData];

    if (someData !== undefined) {
      if (typeof someData === 'object' && Array.isArray(someData)) {
        result = someData
          .map(DomainModelHelper.ensureDataIsNumber)
          .filter((item) => (options.filterFalsy ? !!item : true));
      }
    }

    return result;
  }
}
