import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  CreateAccount,
  LoginResponse,
  LoginSession,
  ProfileSettings,
  VerificationEmailSentResponse,
} from '@digital-platform/users/domain';

interface Response<T> {
  data: T;
}

@Injectable({
  providedIn: 'root',
})
export class LoginSessionService {
  constructor(private httpClient: HttpClient) {}

  public getSession(): Observable<LoginSession> {
    return this.httpClient
      .get<Response<LoginSession>>('/api/users/me', { withCredentials: true })
      .pipe(
        map((response) => response?.data),
        catchError((error: HttpErrorResponse): Observable<LoginSession> => {
          if (error.status === 401) {
            return of({
              user: null,
              error: '',
            });
          }
          return throwError(error);
        })
      );
  }

  public login(username: string, password: string): Observable<LoginResponse> {
    const body = {
      username,
      password,
    };
    return this.httpClient
      .post<Response<LoginResponse>>('/api/users/login', body, {
        withCredentials: true,
      })
      .pipe(
        map((response) => response.data),
        catchError((error: HttpErrorResponse): Observable<LoginResponse> => {
          let message = error.statusText;

          if (error.error?.message) {
            message = error.error?.message;
          }

          return of({
            user: null,
            error: message,
          });
        })
      );
  }

  public logout(): Observable<LoginSession> {
    return this.httpClient
      .post<Response<LoginSession>>('/api/users/logout', {
        withCredentials: true,
      })
      .pipe(
        map(() => {
          localStorage.removeItem('active-waste-profile');

          return null;
        })
      );
  }

  public updateUser(userData: ProfileSettings): Observable<LoginSession> {
    return this.httpClient
      .patch<Response<LoginSession>>('/api/users/update', userData, {
        withCredentials: true,
      })
      .pipe(
        map((response) => response?.data),
        catchError((error: HttpErrorResponse): Observable<LoginSession> => {
          if (error.status === 401) {
            return of({ user: null, error: '' });
          }
          return throwError(error);
        })
      );
  }

  public forgotPassword(email: string): Observable<string> {
    const body = {
      email,
    };
    return this.httpClient
      .post<Response<string>>('/api/users/forgot-password', body, {
        withCredentials: false,
      })
      .pipe(map((response) => null));
  }

  public changePassword(): Observable<string> {
    return this.httpClient
      .post<Response<string>>('/api/users/change-password', {
        withCredentials: true,
      })
      .pipe(map((response) => null));
  }

  public resetPassword(newPassword: string): Observable<string> {
    const body = {
      newPassword,
    };
    return this.httpClient
      .post<Response<string>>('/api/users/reset-password', body, {
        withCredentials: true,
      })
      .pipe(map((response) => null));
  }

  public createAccount(newUserData: CreateAccount) {
    return this.httpClient.post('/api/users/create-account', newUserData, {
      withCredentials: true,
    });
  }

  // public sendVerificationEmail(
  //   email: string,
  //   password: string
  // ): Observable<VerificationEmailSentResponse> {
  //   const body = {
  //     email,
  //     password,
  //   };
  //   return this.httpClient
  //     .post<Response<string>>('/api/users/verification-email', body, {
  //       withCredentials: true,
  //     })
  //     .pipe(map((response) => null));
  // }

  public onboardingComplete(
    onboardingComplete: boolean
  ): Observable<LoginSession> {
    const body = {
      onboardingComplete,
    };
    return this.httpClient
      .post<Response<LoginSession>>('/api/users/onboarding-complete', body, {
        withCredentials: true,
      })
      .pipe(map((response) => response.data));
  }
}
